<form [formGroup]="form">
    <ui-form-textarea class="mb-0"
                      [placeholder]="'global.forms.labels.note' | translate"
                      [label]="null"
                      [formControlName]="abstractNoteFormField.Note">

    </ui-form-textarea>

    <ui-button-row>
        <ui-button
            [disabled]="form.status === 'INVALID' || pending"
            [label]="'global.labels.submit'"
            (handlerClick)="handlerSubmit()">

        </ui-button>
        <ui-button [disabled]="pending"
                   [label]="'global.labels.cancel'"
                   (handlerClick)="cancelClick.emit()"
                   type="outline">

        </ui-button>
    </ui-button-row>
</form>
