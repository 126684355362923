/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { HistoryData as AnalyticCommonModelsHistoryData } from '../models/AnalyticCommon/Models/history-data';
import { ValError as AnalyticCommonModelsValError } from '../models/AnalyticCommon/Models/val-error';
import { ValidatorStatusBo as AnalyticCommonModelsValidatorStatusBo } from '../models/AnalyticCommon/Models/validator-status-bo';

@Injectable({
  providedIn: 'root',
})
export class MonitoringWebService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiMonitoringWebValidatorsGet
   */
  static readonly ApiMonitoringWebValidatorsGetPath = '/api/MonitoringWeb/Validators';

  /**
   * ### Claim
   *  boservice.monitoringweb.validators
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMonitoringWebValidatorsGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMonitoringWebValidatorsGet$Plain$Response(params?: {
    number?: string;
    stId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<AnalyticCommonModelsValidatorStatusBo>>> {

    const rb = new RequestBuilder(this.rootUrl, MonitoringWebService.ApiMonitoringWebValidatorsGetPath, 'get');
    if (params) {
      rb.query('number', params.number, {});
      rb.query('stId', params.stId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AnalyticCommonModelsValidatorStatusBo>>;
      })
    );
  }

  /**
   * ### Claim
   *  boservice.monitoringweb.validators
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMonitoringWebValidatorsGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMonitoringWebValidatorsGet$Plain(params?: {
    number?: string;
    stId?: number;
  },
  context?: HttpContext

): Observable<Array<AnalyticCommonModelsValidatorStatusBo>> {

    return this.apiMonitoringWebValidatorsGet$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<AnalyticCommonModelsValidatorStatusBo>>) => r.body as Array<AnalyticCommonModelsValidatorStatusBo>)
    );
  }

  /**
   * ### Claim
   *  boservice.monitoringweb.validators
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMonitoringWebValidatorsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMonitoringWebValidatorsGet$Response(params?: {
    number?: string;
    stId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<AnalyticCommonModelsValidatorStatusBo>>> {

    const rb = new RequestBuilder(this.rootUrl, MonitoringWebService.ApiMonitoringWebValidatorsGetPath, 'get');
    if (params) {
      rb.query('number', params.number, {});
      rb.query('stId', params.stId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AnalyticCommonModelsValidatorStatusBo>>;
      })
    );
  }

  /**
   * ### Claim
   *  boservice.monitoringweb.validators
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMonitoringWebValidatorsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMonitoringWebValidatorsGet(params?: {
    number?: string;
    stId?: number;
  },
  context?: HttpContext

): Observable<Array<AnalyticCommonModelsValidatorStatusBo>> {

    return this.apiMonitoringWebValidatorsGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<AnalyticCommonModelsValidatorStatusBo>>) => r.body as Array<AnalyticCommonModelsValidatorStatusBo>)
    );
  }

  /**
   * Path part for operation apiMonitoringWebGetHistoryDataGet
   */
  static readonly ApiMonitoringWebGetHistoryDataGetPath = '/api/MonitoringWeb/GetHistoryData';

  /**
   * ### Claim
   *  boservice.monitoringweb.gethistorydata
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMonitoringWebGetHistoryDataGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMonitoringWebGetHistoryDataGet$Plain$Response(params?: {
    lang?: string;
    number?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<AnalyticCommonModelsHistoryData>>> {

    const rb = new RequestBuilder(this.rootUrl, MonitoringWebService.ApiMonitoringWebGetHistoryDataGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('number', params.number, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AnalyticCommonModelsHistoryData>>;
      })
    );
  }

  /**
   * ### Claim
   *  boservice.monitoringweb.gethistorydata
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMonitoringWebGetHistoryDataGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMonitoringWebGetHistoryDataGet$Plain(params?: {
    lang?: string;
    number?: number;
  },
  context?: HttpContext

): Observable<Array<AnalyticCommonModelsHistoryData>> {

    return this.apiMonitoringWebGetHistoryDataGet$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<AnalyticCommonModelsHistoryData>>) => r.body as Array<AnalyticCommonModelsHistoryData>)
    );
  }

  /**
   * ### Claim
   *  boservice.monitoringweb.gethistorydata
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMonitoringWebGetHistoryDataGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMonitoringWebGetHistoryDataGet$Response(params?: {
    lang?: string;
    number?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<AnalyticCommonModelsHistoryData>>> {

    const rb = new RequestBuilder(this.rootUrl, MonitoringWebService.ApiMonitoringWebGetHistoryDataGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('number', params.number, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AnalyticCommonModelsHistoryData>>;
      })
    );
  }

  /**
   * ### Claim
   *  boservice.monitoringweb.gethistorydata
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMonitoringWebGetHistoryDataGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMonitoringWebGetHistoryDataGet(params?: {
    lang?: string;
    number?: number;
  },
  context?: HttpContext

): Observable<Array<AnalyticCommonModelsHistoryData>> {

    return this.apiMonitoringWebGetHistoryDataGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<AnalyticCommonModelsHistoryData>>) => r.body as Array<AnalyticCommonModelsHistoryData>)
    );
  }

  /**
   * Path part for operation apiMonitoringWebReloadValidatorsGet
   */
  static readonly ApiMonitoringWebReloadValidatorsGetPath = '/api/MonitoringWeb/ReloadValidators';

  /**
   * ### Claim
   *  boservice.monitoringweb.reloadvalidators
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMonitoringWebReloadValidatorsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMonitoringWebReloadValidatorsGet$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, MonitoringWebService.ApiMonitoringWebReloadValidatorsGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * ### Claim
   *  boservice.monitoringweb.reloadvalidators
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMonitoringWebReloadValidatorsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMonitoringWebReloadValidatorsGet(params?: {
  },
  context?: HttpContext

): Observable<void> {

    return this.apiMonitoringWebReloadValidatorsGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiMonitoringWebErrorsGet
   */
  static readonly ApiMonitoringWebErrorsGetPath = '/api/MonitoringWeb/Errors';

  /**
   * ### Claim
   *  boservice.monitoringweb.errors
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMonitoringWebErrorsGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMonitoringWebErrorsGet$Plain$Response(params?: {
    lang?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<AnalyticCommonModelsValError>>> {

    const rb = new RequestBuilder(this.rootUrl, MonitoringWebService.ApiMonitoringWebErrorsGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AnalyticCommonModelsValError>>;
      })
    );
  }

  /**
   * ### Claim
   *  boservice.monitoringweb.errors
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMonitoringWebErrorsGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMonitoringWebErrorsGet$Plain(params?: {
    lang?: string;
  },
  context?: HttpContext

): Observable<Array<AnalyticCommonModelsValError>> {

    return this.apiMonitoringWebErrorsGet$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<AnalyticCommonModelsValError>>) => r.body as Array<AnalyticCommonModelsValError>)
    );
  }

  /**
   * ### Claim
   *  boservice.monitoringweb.errors
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMonitoringWebErrorsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMonitoringWebErrorsGet$Response(params?: {
    lang?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<AnalyticCommonModelsValError>>> {

    const rb = new RequestBuilder(this.rootUrl, MonitoringWebService.ApiMonitoringWebErrorsGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AnalyticCommonModelsValError>>;
      })
    );
  }

  /**
   * ### Claim
   *  boservice.monitoringweb.errors
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMonitoringWebErrorsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMonitoringWebErrorsGet(params?: {
    lang?: string;
  },
  context?: HttpContext

): Observable<Array<AnalyticCommonModelsValError>> {

    return this.apiMonitoringWebErrorsGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<AnalyticCommonModelsValError>>) => r.body as Array<AnalyticCommonModelsValError>)
    );
  }

}
