<div class="form-group" [ngClass]="additionalGroupClass" [class.form-group-disabled]="disabled">

    <label *ngIf="label" class="d-block"
        [class.mb-1]="!hint"
        [class.mb-0]="hint"
        [attr.for]="id"
    >{{ label }}<sup *ngIf="isRequiredField">*</sup>
    </label>

    <div *ngIf="hint" class="hint mb-1" [innerHTML]="hint"></div>

    <textarea
        class="form-control"
        [attr.id]="id"
        [disabled]="disabled ? disabled : null"
        [attr.placeholder]="placeholder"
        [class.is-invalid]="inputControl?.touched && !inputControl?.pristine && inputControl?.invalid"
        [class.is-valid]="hasBlur && inputControl?.valid"
        (keydown.enter)="handlePressEnter($event)"
        (blur)="hasBlur = true"
        (change)="changeValue($event)"
        (keyup)="changeValue($event)"
    >{{value}}</textarea>

    <div class="invalid-feedback" *ngIf="inputControl?.errors && inputControl?.touched && hasBlur">
        <span class="invalid-feedback-box">{{ inputControl.errors | validationMessage }}</span>
    </div>
</div>
