import { Component, inject } from '@angular/core';
import { AsyncPipe, NgIf } from '@angular/common';
import { LangCheckerModule } from '@ui/lang-checker/lang-checker.module';
import { LogoModule } from '@ui/logo/logo.module';
import { UserCardModule } from '@ui/user-card/user-card.module';
import { SettingsDesignLogoModel, SettingsService } from '@libs/settings';
import { Observable } from 'rxjs';
import { AdminUserModel } from '@libs/admin-user/admin-user.model';
import { LangModel } from '@libs/lang/lang.model';
import { LangService } from '@libs/lang/lang.service';
import { AdminUserService } from '@libs/admin-user/admin-user.service';
import { IconModule } from '@ui/icon/icon.module';
import { TranslateModule } from '@ngx-translate/core';
import { RouterLink } from '@angular/router';
import { RouteEntity } from '@core/router/route.entity';


@Component({
    selector: 'mon-tech-header-mon-tech',
    standalone: true,
    templateUrl: './header-mon-tech.component.html',
    styleUrls: ['./header-mon-tech.component.scss'],
    imports: [
        AsyncPipe,
        LangCheckerModule,
        LogoModule,
        NgIf,
        UserCardModule,
        IconModule,
        TranslateModule,
        RouterLink,
    ],
})
export class HeaderMonTechComponent {
    readonly #settingsService: SettingsService = inject(SettingsService);
    readonly #adminUserService: AdminUserService = inject(AdminUserService);
    readonly #langService: LangService = inject(LangService);

    public readonly routeEntity: typeof RouteEntity = RouteEntity;

    /**
     * Logo header
     */
    public readonly logo: SettingsDesignLogoModel = this.#settingsService.settings?.logo?.srcHeader;
    /**
     * User data
     */
    public readonly user$: Observable<AdminUserModel> = this.#adminUserService.user;
    /**
     * List languages
     */
    public readonly languages: Observable<LangModel[]> = this.#langService.languages;
    /**
     * Selected lang code
     */
    public readonly selectedLang: string = this.#langService.currentLangCode;

    public handlerChangeLang(lang: LangModel): void {
        this.#langService.currentLang = lang;
        window.location.reload();
    }
}
