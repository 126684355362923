import { Routes } from '@angular/router';
import { RouteEntity } from './route.entity';
import { CookieMessageComponent } from '@ui/cookie-message/cookie-message.component';
import { HeaderComponent } from '@ui/header/header.component';
import { FooterComponent } from '@ui/footer/footer.component';
import { AuthGuard } from '@core/auth/auth.guard';
import { AuthWithTempPasswordGuard } from '@core/auth/auth-with-temp-password.guard';
import { CookiePolicyTextComponent } from '@ui/cookie-policy-text/cookie-policy-text.component';
import { RoutingQueryParamsName } from '@core/router/routing.params';


export const commonModuleRoutes: Routes = [
    {
        path: RouteEntity.Empty,
        component: CookieMessageComponent,
        outlet: 'cookie-message',
    },
    {
        path: RouteEntity.Empty,
        component: HeaderComponent,
        outlet: 'header',
    },
    {
        path: RouteEntity.Empty,
        component: FooterComponent,
        outlet: 'footer',
    },
    {
        path: RouteEntity.Logout,
        loadChildren: () =>
            import('@ui/page-logout/page-logout.module').then(
                (m) => m.PageLogoutModule,
            ),
        canLoad: [AuthGuard],
    },
    {
        path: RouteEntity.Login,
        loadChildren: () =>
            import('@ui/page-login/page-login.module').then(
                (m) => m.PageLoginModule,
            ),
    },
    {
        path: RouteEntity.UserSettings,
        loadChildren: () =>
            import('@ui/page-settings/page-settings.module').then(
                (m) => m.PageSettingsModule,
            ),
        canLoad: [AuthWithTempPasswordGuard],
    },
    {
        path: RouteEntity.Error + '/:' + RoutingQueryParamsName.Code,
        loadChildren: () =>
            import('@ui/error-container/error-container.module').then(
                (m) => m.ErrorContainerModule,
            ),
    },
    {
        path: RouteEntity.CookiePolicy,
        component: CookiePolicyTextComponent,
    },
];

export const commonErrorRoutes: Routes = [
    {
        path: '**',
        redirectTo: RouteEntity.Error + '/' + RouteEntity.Error404,
    },
];
