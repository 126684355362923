import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormAbstractNoteComponent } from './form-abstract-note.component';
import { ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from '@ui/form/button/button.module';
import { ButtonRowModule } from '@ui/form/button-row/button-row.module';
import { TranslateModule } from '@ngx-translate/core';
import { FormTextareaComponent } from '@ui/form/form-textarea/form-textarea.component';


@NgModule({
    declarations: [FormAbstractNoteComponent],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        ButtonModule,
        ButtonRowModule,
        TranslateModule,
        FormTextareaComponent,
    ],
    exports: [FormAbstractNoteComponent],
})
export class FormAbstractNoteModule {
}
