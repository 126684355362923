import { InjectionToken, Injector } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { DialogComponent } from './dialog.component';
import { ComponentType } from '@angular/cdk/overlay';
import { Observable } from 'rxjs';

export interface DialogParams {
  templateId?: string;
  dialogRef: MatDialogRef<DialogComponent>;
  confirmFunction?: any;
  cancelFunction?: any;
  serviceParams?: any;
  pending$?: Observable<boolean> | undefined;

  noteRequired?: boolean | undefined;
}

export interface DialogData {
  title: string;
  component: ComponentType<any>;
  injector: Injector;
  params: DialogParams;
}

export const DIALOG_DATA = new InjectionToken<DialogParams>('Dialog Data');

export enum DialogWidth {
    SM = '450px',
    MD = '650px',
    LG = '800px',
    XL = '960px',
    XXL = '1200px',
}

