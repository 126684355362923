import { inject, OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { marker } from '@colsen1991/ngx-translate-extract-marker';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LanguageState } from '@core/localization/language.state';


const VALIDATION_MESSAGES_TRANSLATION_KEYS: Record<string, string> = {
    Required: marker('validation.messages.required'),
    DateFormat: marker('validation.messages.dateFormat'),
    Email: marker('validation.messages.email'),
    MinDate: marker('validation.messages.minDate'),
    MinLength: marker('validation.messages.minlength'),
    MaxLength: marker('validation.messages.maxlength'),
    Min: marker('validation.messages.min'),
    Max: marker('validation.messages.max'),
    NeedSelectFromList: marker('validation.messages.needSelectFromList'),
    Mismatch: marker('validation.messages.mismatch'),
    Phone: marker('validation.messages.phone'),
    Pattern: marker('validation.messages.pattern'),
    RequiredAllLanguagesValue: marker('validation.messages.requiredAllLanguagesValue'),
    RequiredDefaultLanguageValue: marker('validation.messages.requiredDefaultLanguageValue'),
    Equal: marker('validation.messages.equal'),
};

@Pipe({
    name: 'validationMessage',
    standalone: true,
    pure: false,
})
export class ValidationMessagePipe implements PipeTransform, OnDestroy {
    readonly #translate: TranslateService = inject(TranslateService);
    readonly #languageState: LanguageState = inject(LanguageState);

    private validationMap: Record<string, (data?: unknown, params?: unknown) => string>;
    private previousInputValue: ValidationErrors;
    private previousOutputValue: string = '';

    private destroy$: Subject<void> = new Subject<void>();

    constructor() {
        this.#languageState.languages$
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => {
                this.previousInputValue = null;

                this.validationMap = {
                    required: () => this.getInstant(VALIDATION_MESSAGES_TRANSLATION_KEYS.Required),
                    dateFormat: () => this.getInstant(VALIDATION_MESSAGES_TRANSLATION_KEYS.DateFormat),
                    email: () => this.getInstant(VALIDATION_MESSAGES_TRANSLATION_KEYS.Email),
                    minDate: () => this.getInstant(VALIDATION_MESSAGES_TRANSLATION_KEYS.MinDate),
                    minlength: (data: { requiredLength: number; actualLength: number }) => {
                        const rawString: string = this.getInstant(VALIDATION_MESSAGES_TRANSLATION_KEYS.MinLength);

                        return rawString.replace('{{value}}', data.requiredLength.toString())
                            .replace('{{actualValue}}', data.actualLength.toString());
                    },
                    maxlength: (data: { requiredLength: number; actualLength: number }) => {
                        const rawString: string = this.getInstant(VALIDATION_MESSAGES_TRANSLATION_KEYS.MaxLength);

                        return rawString.replace('{{value}}', data.requiredLength.toString())
                            .replace('{{actualValue}}', data.actualLength.toString());
                    },
                    min: (data: { actual: number; min: number }) => {
                        const rawString: string = this.getInstant(VALIDATION_MESSAGES_TRANSLATION_KEYS.MinLength);

                        return rawString.replace('{{value}}', data.min.toString())
                            .replace('{{actualValue}}', data.actual.toString());
                    },
                    max: (data: { actual: number; max: number }) => {
                        const rawString: string = this.getInstant(VALIDATION_MESSAGES_TRANSLATION_KEYS.MaxLength);

                        return rawString.replace('{{value}}', data.max.toString())
                            .replace('{{actualValue}}', data.actual.toString());
                    },
                    needSelectFromList: () => this.getInstant(VALIDATION_MESSAGES_TRANSLATION_KEYS.NeedSelectFromList),
                    isSupplierOrIsBuyerRequired: () => this.getInstant(VALIDATION_MESSAGES_TRANSLATION_KEYS.IsSupplierOrIsBuyerRequired),
                    mismatch: () => this.getInstant(VALIDATION_MESSAGES_TRANSLATION_KEYS.Mismatch),
                    phone: () => this.getInstant(VALIDATION_MESSAGES_TRANSLATION_KEYS.Phone),
                    pattern: () => this.getInstant(VALIDATION_MESSAGES_TRANSLATION_KEYS.Pattern),
                    equal: () => this.getInstant(VALIDATION_MESSAGES_TRANSLATION_KEYS.Equal),
                    requiredAllLanguagesValue: () => this.getInstant(VALIDATION_MESSAGES_TRANSLATION_KEYS.RequiredAllLanguagesValue),
                    requiredDefaultLanguageValue: (data: { defaultLanguage: string; }) => {
                        const rawString: string = this.getInstant(VALIDATION_MESSAGES_TRANSLATION_KEYS.RequiredDefaultLanguageValue);

                        return rawString.replace('{{defaultLangCode}}', data.defaultLanguage);
                    },
                };
            });
    }

    public transform(errors: ValidationErrors, params?: unknown): string {
        if (this.previousInputValue === errors) {
            return this.previousOutputValue;
        }

        this.previousInputValue = errors;

        if (Object.keys(errors || {}).length === 0 || !this.validationMap) {
            this.previousOutputValue = '';

            return this.previousOutputValue;
        }

        this.previousOutputValue = Object.entries(errors).map(([key, value]: string[]) => {
            const messageFunction: (data?: unknown, params?: unknown) => string = this.validationMap[key];

            if (typeof messageFunction === 'function') {
                return messageFunction(value, params);
            }

            return value;
        }).join('. ');

        return this.previousOutputValue;
    }

    public ngOnDestroy(): void {
        this.destroy$.next(null);
        this.destroy$.complete();
    }

    private getInstant(key: string): string {
        return this.#translate.instant(key);
    }
}
