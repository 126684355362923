import { inject, Injectable, OnDestroy } from '@angular/core';
import {
    AbstractControl,
    FormBuilder,
    FormControl,
    FormControlStatus,
    FormGroup,
    ValidationErrors,
} from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { startWith, takeUntil, tap } from "rxjs/operators";


@Injectable()
export abstract class AbstractForm<K extends string, T extends { [key in keyof T]: AbstractControl }> implements OnDestroy {
    public form: FormGroup<T>;

    protected readonly formBuilder: FormBuilder = inject(FormBuilder);

    private destroy$: Subject<void> = new Subject<void>();

    public ngOnDestroy(): void {
        this.destroy();
    }

    public destroy(): void {
        this.destroy$.next(null);
        this.destroy$.complete();
    }

    public getControlByPath<C = FormControl>(path: K | K[], group?: AbstractControl): C {
        return (group || this.form).get(path) as C;
    }

    public getValueByPath<V>(path: K | K[], group?: AbstractControl): V {
        return (group || this.form).get(path).value as V;
    }

    public getErrorsByPath(path: K | K[], group?: AbstractControl): ValidationErrors {
        return (group || this.form).get(path).errors;
    }

    public watchForStatus(control: AbstractControl): Observable<FormControlStatus> {
        return control.statusChanges
            .pipe(
                takeUntil(this.destroy$),
            );
    }

    public watchForChanges<V>(control: AbstractControl, startValue?: V): Observable<V> {
        return control.valueChanges.pipe(
            startValue !== undefined ? startWith(startValue) : tap(),
            takeUntil(this.destroy$),
        );
    }

    public watchForValueChanges<M>(path: K | K[], group?: AbstractControl): Observable<M> {
        const control: AbstractControl = this.getControlByPath(path, group);

        return control.valueChanges
            .pipe(takeUntil(this.destroy$));
    }

    public watchForValueChangesWithStart<M>(path: K | K[], group?: AbstractControl): Observable<M> {
        const control: AbstractControl = this.getControlByPath(path, group);

        return this.watchForChanges<M>(control, control.value);
    }

    protected registerFormEffect<T>(stream$: Observable<T>, sideEffectFn: (data?: T) => void = () => {}): void {
        stream$.pipe(takeUntil(this.destroy$))
            .subscribe(sideEffectFn);
    }

    protected build(group: Partial<T>): void {
        this.form = new FormGroup<T>(group as T);
    }

    public abstract createReactiveForm<M>(data?: M): FormGroup<T>;
}
