/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AddTariffTicketPeriodDateRequest as TariffEditorApiContractAddTariffTicketPeriodDateRequest } from '../models/TariffEditorApi/Contract/add-tariff-ticket-period-date-request';
import { AddTariffTicketPeriodRequest as TariffEditorApiContractAddTariffTicketPeriodRequest } from '../models/TariffEditorApi/Contract/add-tariff-ticket-period-request';
import { AddTariffTicketPeriodResponse as TariffEditorApiContractAddTariffTicketPeriodResponse } from '../models/TariffEditorApi/Contract/add-tariff-ticket-period-response';
import { GetTariffTicketPeriodDataResponse as TariffEditorApiContractGetTariffTicketPeriodDataResponse } from '../models/TariffEditorApi/Contract/get-tariff-ticket-period-data-response';

@Injectable({
  providedIn: 'root',
})
export class PeriodsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiTariffAddTariffTicketPeriodPost
   */
  static readonly ApiTariffAddTariffTicketPeriodPostPath = '/api/Tariff/AddTariffTicketPeriod';

  /**
   * creation of a special period.
   *
   * ### Claim
   *  boservice.tariff.addtariffticketperiod
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTariffAddTariffTicketPeriodPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTariffAddTariffTicketPeriodPost$Plain$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;
    body?: TariffEditorApiContractAddTariffTicketPeriodRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<TariffEditorApiContractAddTariffTicketPeriodResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PeriodsService.ApiTariffAddTariffTicketPeriodPostPath, 'post');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TariffEditorApiContractAddTariffTicketPeriodResponse>;
      })
    );
  }

  /**
   * creation of a special period.
   *
   * ### Claim
   *  boservice.tariff.addtariffticketperiod
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTariffAddTariffTicketPeriodPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTariffAddTariffTicketPeriodPost$Plain(params?: {

    /**
     * Interface language
     */
    lang?: string;
    body?: TariffEditorApiContractAddTariffTicketPeriodRequest
  },
  context?: HttpContext

): Observable<TariffEditorApiContractAddTariffTicketPeriodResponse> {

    return this.apiTariffAddTariffTicketPeriodPost$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<TariffEditorApiContractAddTariffTicketPeriodResponse>) => r.body as TariffEditorApiContractAddTariffTicketPeriodResponse)
    );
  }

  /**
   * creation of a special period.
   *
   * ### Claim
   *  boservice.tariff.addtariffticketperiod
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTariffAddTariffTicketPeriodPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTariffAddTariffTicketPeriodPost$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;
    body?: TariffEditorApiContractAddTariffTicketPeriodRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<TariffEditorApiContractAddTariffTicketPeriodResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PeriodsService.ApiTariffAddTariffTicketPeriodPostPath, 'post');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TariffEditorApiContractAddTariffTicketPeriodResponse>;
      })
    );
  }

  /**
   * creation of a special period.
   *
   * ### Claim
   *  boservice.tariff.addtariffticketperiod
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTariffAddTariffTicketPeriodPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTariffAddTariffTicketPeriodPost(params?: {

    /**
     * Interface language
     */
    lang?: string;
    body?: TariffEditorApiContractAddTariffTicketPeriodRequest
  },
  context?: HttpContext

): Observable<TariffEditorApiContractAddTariffTicketPeriodResponse> {

    return this.apiTariffAddTariffTicketPeriodPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<TariffEditorApiContractAddTariffTicketPeriodResponse>) => r.body as TariffEditorApiContractAddTariffTicketPeriodResponse)
    );
  }

  /**
   * Path part for operation apiTariffAddTariffTicketPeriodDatePost
   */
  static readonly ApiTariffAddTariffTicketPeriodDatePostPath = '/api/Tariff/AddTariffTicketPeriodDate';

  /**
   * Adding Special Period Intervals.
   *
   * ### Claim
   *  boservice.tariff.addtariffticketperioddate
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTariffAddTariffTicketPeriodDatePost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTariffAddTariffTicketPeriodDatePost$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;
    body?: TariffEditorApiContractAddTariffTicketPeriodDateRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, PeriodsService.ApiTariffAddTariffTicketPeriodDatePostPath, 'post');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Adding Special Period Intervals.
   *
   * ### Claim
   *  boservice.tariff.addtariffticketperioddate
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTariffAddTariffTicketPeriodDatePost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTariffAddTariffTicketPeriodDatePost(params?: {

    /**
     * Interface language
     */
    lang?: string;
    body?: TariffEditorApiContractAddTariffTicketPeriodDateRequest
  },
  context?: HttpContext

): Observable<void> {

    return this.apiTariffAddTariffTicketPeriodDatePost$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiTariffRemoveTariffTicketPeriodDelete
   */
  static readonly ApiTariffRemoveTariffTicketPeriodDeletePath = '/api/Tariff/RemoveTariffTicketPeriod';

  /**
   * Deleting a special period.
   *
   * ### Claim
   *  boservice.tariff.removetariffticketperiod
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTariffRemoveTariffTicketPeriodDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTariffRemoveTariffTicketPeriodDelete$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * special period id
     */
    ttpId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, PeriodsService.ApiTariffRemoveTariffTicketPeriodDeletePath, 'delete');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('ttpId', params.ttpId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Deleting a special period.
   *
   * ### Claim
   *  boservice.tariff.removetariffticketperiod
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTariffRemoveTariffTicketPeriodDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTariffRemoveTariffTicketPeriodDelete(params?: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * special period id
     */
    ttpId?: number;
  },
  context?: HttpContext

): Observable<void> {

    return this.apiTariffRemoveTariffTicketPeriodDelete$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiTariffRemoveTariffTicketPeriodDateDelete
   */
  static readonly ApiTariffRemoveTariffTicketPeriodDateDeletePath = '/api/Tariff/RemoveTariffTicketPeriodDate';

  /**
   * Deleting Special Period Validity Intervals.
   *
   * ### Claim
   *  boservice.tariff.removetariffticketperioddate
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTariffRemoveTariffTicketPeriodDateDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTariffRemoveTariffTicketPeriodDateDelete$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * special period id
     */
    ttpId?: number;

    /**
     * list of start dates of special period intervals
     */
    dateFromArr?: Array<string>;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, PeriodsService.ApiTariffRemoveTariffTicketPeriodDateDeletePath, 'delete');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('ttpId', params.ttpId, {});
      rb.query('dateFromArr', params.dateFromArr, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Deleting Special Period Validity Intervals.
   *
   * ### Claim
   *  boservice.tariff.removetariffticketperioddate
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTariffRemoveTariffTicketPeriodDateDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTariffRemoveTariffTicketPeriodDateDelete(params?: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * special period id
     */
    ttpId?: number;

    /**
     * list of start dates of special period intervals
     */
    dateFromArr?: Array<string>;
  },
  context?: HttpContext

): Observable<void> {

    return this.apiTariffRemoveTariffTicketPeriodDateDelete$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiTariffGetTariffTicketPeriodDataGet
   */
  static readonly ApiTariffGetTariffTicketPeriodDataGetPath = '/api/Tariff/GetTariffTicketPeriodData';

  /**
   * Getting data about a special period.
   *
   * ### Claim
   *  boservice.tariff.gettariffticketperioddata
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTariffGetTariffTicketPeriodDataGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTariffGetTariffTicketPeriodDataGet$Plain$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * special period id
     */
    ttpId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<TariffEditorApiContractGetTariffTicketPeriodDataResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PeriodsService.ApiTariffGetTariffTicketPeriodDataGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('ttpId', params.ttpId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TariffEditorApiContractGetTariffTicketPeriodDataResponse>;
      })
    );
  }

  /**
   * Getting data about a special period.
   *
   * ### Claim
   *  boservice.tariff.gettariffticketperioddata
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTariffGetTariffTicketPeriodDataGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTariffGetTariffTicketPeriodDataGet$Plain(params?: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * special period id
     */
    ttpId?: number;
  },
  context?: HttpContext

): Observable<TariffEditorApiContractGetTariffTicketPeriodDataResponse> {

    return this.apiTariffGetTariffTicketPeriodDataGet$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<TariffEditorApiContractGetTariffTicketPeriodDataResponse>) => r.body as TariffEditorApiContractGetTariffTicketPeriodDataResponse)
    );
  }

  /**
   * Getting data about a special period.
   *
   * ### Claim
   *  boservice.tariff.gettariffticketperioddata
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTariffGetTariffTicketPeriodDataGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTariffGetTariffTicketPeriodDataGet$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * special period id
     */
    ttpId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<TariffEditorApiContractGetTariffTicketPeriodDataResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PeriodsService.ApiTariffGetTariffTicketPeriodDataGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('ttpId', params.ttpId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TariffEditorApiContractGetTariffTicketPeriodDataResponse>;
      })
    );
  }

  /**
   * Getting data about a special period.
   *
   * ### Claim
   *  boservice.tariff.gettariffticketperioddata
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTariffGetTariffTicketPeriodDataGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTariffGetTariffTicketPeriodDataGet(params?: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * special period id
     */
    ttpId?: number;
  },
  context?: HttpContext

): Observable<TariffEditorApiContractGetTariffTicketPeriodDataResponse> {

    return this.apiTariffGetTariffTicketPeriodDataGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<TariffEditorApiContractGetTariffTicketPeriodDataResponse>) => r.body as TariffEditorApiContractGetTariffTicketPeriodDataResponse)
    );
  }

}
