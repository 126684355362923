import { FormControl, FormGroup } from '@angular/forms';
import { Injectable } from '@angular/core';
import { AbstractForm } from '@core/abstract/form';


export enum AbstractNoteFormField {
    Note = 'note',
}

export interface AbstractNoteFormModel {
    [AbstractNoteFormField.Note]: FormControl<string>;
}

@Injectable()
export class AbstractNoteFormService extends AbstractForm<AbstractNoteFormField, AbstractNoteFormModel> {
    public createReactiveForm(): FormGroup<AbstractNoteFormModel> {
        this.build({
            [AbstractNoteFormField.Note]: new FormControl<string>(null, []),
        });

        return this.form;
    }
}
