import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogComponent } from './dialog.component';
import { TranslateModule } from '@ngx-translate/core';
import { MatDialogModule } from '@angular/material/dialog';


@NgModule({
    declarations: [DialogComponent],
    imports: [CommonModule, MatDialogModule, TranslateModule],
    exports: [DialogComponent],
})
export class DialogModule {
}
