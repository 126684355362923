/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AddPushMsgRequest as ClientSupportApiContractAddPushMsgRequest } from '../models/ClientSupportApi/Contract/add-push-msg-request';
import { ClientIdChange as ClientSupportApiContractClientIdChange } from '../models/ClientSupportApi/Contract/client-id-change';
import { ClientInfo as ClientSupportApiContractClientInfo } from '../models/ClientSupportApi/Contract/client-info';
import { ClientMedium as ClientSupportApiContractClientMedium } from '../models/ClientSupportApi/Contract/client-medium';
import { ClientPaymentMethod as ClientSupportApiContractClientPaymentMethod } from '../models/ClientSupportApi/Contract/client-payment-method';
import { ClientTicket as ClientSupportApiContractClientTicket } from '../models/ClientSupportApi/Contract/client-ticket';
import { AddTicketRequest as ClientSupportApiContractCourtesyAddTicketRequest } from '../models/ClientSupportApi/Contract/Courtesy/add-ticket-request';
import { ListAvailTicketResponse as ClientSupportApiContractCourtesyListAvailTicketResponse } from '../models/ClientSupportApi/Contract/Courtesy/list-avail-ticket-response';
import { ListRouteResponse as ClientSupportApiContractCourtesyListRouteResponse } from '../models/ClientSupportApi/Contract/Courtesy/list-route-response';
import { ListStopResponse as ClientSupportApiContractCourtesyListStopResponse } from '../models/ClientSupportApi/Contract/Courtesy/list-stop-response';
import { ListTicketServiceResponse as ClientSupportApiContractCourtesyListTicketServiceResponse } from '../models/ClientSupportApi/Contract/Courtesy/list-ticket-service-response';
import { GetClientDataIdResponse as ClientSupportApiContractGetClientDataIdResponse } from '../models/ClientSupportApi/Contract/get-client-data-id-response';
import { GetPtTicketDataResponse as ClientSupportApiContractGetPtTicketDataResponse } from '../models/ClientSupportApi/Contract/get-pt-ticket-data-response';
import { HistoryItem as ClientSupportApiContractHistoryItem } from '../models/ClientSupportApi/Contract/history-item';
import { ListClientResponse as ClientSupportApiContractListClientResponse } from '../models/ClientSupportApi/Contract/list-client-response';
import { ListTrmResponse as ClientSupportApiContractListTrmResponse } from '../models/ClientSupportApi/Contract/list-trm-response';
import { MediumType as ClientSupportApiContractMediumType } from '../models/ClientSupportApi/Contract/medium-type';
import { MoveUid as ClientSupportApiContractMoveUid } from '../models/ClientSupportApi/Contract/move-uid';
import { AddOpNoteRequest as ClientSupportApiContractNotesAddOpNoteRequest } from '../models/ClientSupportApi/Contract/Notes/add-op-note-request';
import { AddSupportNoteRequest as ClientSupportApiContractNotesAddSupportNoteRequest } from '../models/ClientSupportApi/Contract/Notes/add-support-note-request';
import { ListOpNoteResponse as ClientSupportApiContractNotesListOpNoteResponse } from '../models/ClientSupportApi/Contract/Notes/list-op-note-response';
import { SetClientDataRequest as ClientSupportApiContractSetClientDataRequest } from '../models/ClientSupportApi/Contract/set-client-data-request';
import { SetClientPrivRequest as ClientSupportApiContractSetClientPrivRequest } from '../models/ClientSupportApi/Contract/set-client-priv-request';
import { TicketInfo as ClientSupportApiContractTicketInfo } from '../models/ClientSupportApi/Contract/ticket-info';
import { TicketTotal as ClientSupportApiContractTicketTotal } from '../models/ClientSupportApi/Contract/ticket-total';
import { UidValid as ClientSupportApiContractUidValid } from '../models/ClientSupportApi/Contract/uid-valid';
import { ValidateResult as ClientSupportApiContractValidateResult } from '../models/ClientSupportApi/Contract/validate-result';
import { Zone as ClientSupportApiContractZone } from '../models/ClientSupportApi/Contract/zone';

@Injectable({
  providedIn: 'root',
})
export class SupportService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiSupportClientsGet
   */
  static readonly ApiSupportClientsGetPath = '/api/Support/clients';

  /**
   * Obtaining customer data by phone number.
   *
   * ### Claim
   *  boservice.support.get
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSupportClientsGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportClientsGet$Plain$Response(params?: {

    /**
     * Customer phone number
     */
    phone?: string;

    /**
     * Interface language
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ClientSupportApiContractClientInfo>> {

    const rb = new RequestBuilder(this.rootUrl, SupportService.ApiSupportClientsGetPath, 'get');
    if (params) {
      rb.query('phone', params.phone, {});
      rb.query('lang', params.lang, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClientSupportApiContractClientInfo>;
      })
    );
  }

  /**
   * Obtaining customer data by phone number.
   *
   * ### Claim
   *  boservice.support.get
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSupportClientsGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportClientsGet$Plain(params?: {

    /**
     * Customer phone number
     */
    phone?: string;

    /**
     * Interface language
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<ClientSupportApiContractClientInfo> {

    return this.apiSupportClientsGet$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<ClientSupportApiContractClientInfo>) => r.body as ClientSupportApiContractClientInfo)
    );
  }

  /**
   * Obtaining customer data by phone number.
   *
   * ### Claim
   *  boservice.support.get
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSupportClientsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportClientsGet$Response(params?: {

    /**
     * Customer phone number
     */
    phone?: string;

    /**
     * Interface language
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ClientSupportApiContractClientInfo>> {

    const rb = new RequestBuilder(this.rootUrl, SupportService.ApiSupportClientsGetPath, 'get');
    if (params) {
      rb.query('phone', params.phone, {});
      rb.query('lang', params.lang, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClientSupportApiContractClientInfo>;
      })
    );
  }

  /**
   * Obtaining customer data by phone number.
   *
   * ### Claim
   *  boservice.support.get
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSupportClientsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportClientsGet(params?: {

    /**
     * Customer phone number
     */
    phone?: string;

    /**
     * Interface language
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<ClientSupportApiContractClientInfo> {

    return this.apiSupportClientsGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<ClientSupportApiContractClientInfo>) => r.body as ClientSupportApiContractClientInfo)
    );
  }

  /**
   * Path part for operation apiSupportClientsEmailGet
   */
  static readonly ApiSupportClientsEmailGetPath = '/api/Support/clientsEmail';

  /**
   * Receiving customer data by email.
   *
   * ### Claim
   *  boservice.support.getemail
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSupportClientsEmailGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportClientsEmailGet$Plain$Response(params?: {

    /**
     * email
     */
    email?: string;

    /**
     * Interface language
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ClientSupportApiContractClientInfo>> {

    const rb = new RequestBuilder(this.rootUrl, SupportService.ApiSupportClientsEmailGetPath, 'get');
    if (params) {
      rb.query('email', params.email, {});
      rb.query('lang', params.lang, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClientSupportApiContractClientInfo>;
      })
    );
  }

  /**
   * Receiving customer data by email.
   *
   * ### Claim
   *  boservice.support.getemail
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSupportClientsEmailGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportClientsEmailGet$Plain(params?: {

    /**
     * email
     */
    email?: string;

    /**
     * Interface language
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<ClientSupportApiContractClientInfo> {

    return this.apiSupportClientsEmailGet$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<ClientSupportApiContractClientInfo>) => r.body as ClientSupportApiContractClientInfo)
    );
  }

  /**
   * Receiving customer data by email.
   *
   * ### Claim
   *  boservice.support.getemail
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSupportClientsEmailGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportClientsEmailGet$Response(params?: {

    /**
     * email
     */
    email?: string;

    /**
     * Interface language
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ClientSupportApiContractClientInfo>> {

    const rb = new RequestBuilder(this.rootUrl, SupportService.ApiSupportClientsEmailGetPath, 'get');
    if (params) {
      rb.query('email', params.email, {});
      rb.query('lang', params.lang, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClientSupportApiContractClientInfo>;
      })
    );
  }

  /**
   * Receiving customer data by email.
   *
   * ### Claim
   *  boservice.support.getemail
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSupportClientsEmailGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportClientsEmailGet(params?: {

    /**
     * email
     */
    email?: string;

    /**
     * Interface language
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<ClientSupportApiContractClientInfo> {

    return this.apiSupportClientsEmailGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<ClientSupportApiContractClientInfo>) => r.body as ClientSupportApiContractClientInfo)
    );
  }

  /**
   * Path part for operation apiSupportClientsIdUidsGet
   */
  static readonly ApiSupportClientsIdUidsGetPath = '/api/Support/clients/{id}/uids';

  /**
   * Obtaining a list of Client media.
   *
   * ### Claim
   *  boservice.support.getclientmedium
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSupportClientsIdUidsGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportClientsIdUidsGet$Plain$Response(params: {

    /**
     * The customer ID
     */
    id: number;

    /**
     * Carrier status:
     *             0 – all statuses;
     *             1 - active;
     *             2 - remote
     */
    status?: number;

    /**
     * Interface language
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<ClientSupportApiContractClientMedium>>> {

    const rb = new RequestBuilder(this.rootUrl, SupportService.ApiSupportClientsIdUidsGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('status', params.status, {});
      rb.query('lang', params.lang, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ClientSupportApiContractClientMedium>>;
      })
    );
  }

  /**
   * Obtaining a list of Client media.
   *
   * ### Claim
   *  boservice.support.getclientmedium
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSupportClientsIdUidsGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportClientsIdUidsGet$Plain(params: {

    /**
     * The customer ID
     */
    id: number;

    /**
     * Carrier status:
     *             0 – all statuses;
     *             1 - active;
     *             2 - remote
     */
    status?: number;

    /**
     * Interface language
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<Array<ClientSupportApiContractClientMedium>> {

    return this.apiSupportClientsIdUidsGet$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<ClientSupportApiContractClientMedium>>) => r.body as Array<ClientSupportApiContractClientMedium>)
    );
  }

  /**
   * Obtaining a list of Client media.
   *
   * ### Claim
   *  boservice.support.getclientmedium
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSupportClientsIdUidsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportClientsIdUidsGet$Response(params: {

    /**
     * The customer ID
     */
    id: number;

    /**
     * Carrier status:
     *             0 – all statuses;
     *             1 - active;
     *             2 - remote
     */
    status?: number;

    /**
     * Interface language
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<ClientSupportApiContractClientMedium>>> {

    const rb = new RequestBuilder(this.rootUrl, SupportService.ApiSupportClientsIdUidsGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('status', params.status, {});
      rb.query('lang', params.lang, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ClientSupportApiContractClientMedium>>;
      })
    );
  }

  /**
   * Obtaining a list of Client media.
   *
   * ### Claim
   *  boservice.support.getclientmedium
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSupportClientsIdUidsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportClientsIdUidsGet(params: {

    /**
     * The customer ID
     */
    id: number;

    /**
     * Carrier status:
     *             0 – all statuses;
     *             1 - active;
     *             2 - remote
     */
    status?: number;

    /**
     * Interface language
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<Array<ClientSupportApiContractClientMedium>> {

    return this.apiSupportClientsIdUidsGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<ClientSupportApiContractClientMedium>>) => r.body as Array<ClientSupportApiContractClientMedium>)
    );
  }

  /**
   * Path part for operation apiSupportClientsIdHistoryGet
   */
  static readonly ApiSupportClientsIdHistoryGetPath = '/api/Support/clients/{id}/history';

  /**
   * Obtaining the history of the Client's operations.
   *
   * ### Claim
   *  boservice.support.getclienthistory
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSupportClientsIdHistoryGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportClientsIdHistoryGet$Plain$Response(params: {

    /**
     * The customer ID
     */
    id: number;

    /**
     * Start date-time
     */
    start?: string;

    /**
     * End date-time
     */
    end?: string;

    /**
     * Operation type:
     *             0 - all
     *             1 - magazine,
     *             2 - replenishment
     */
    otype?: number;

    /**
     * Media type. NULL - all media types
     */
    utype?: number;

    /**
     * Payment method code. NULL - all method
     */
    paymethodcode?: number;

    /**
     * Media ID/NULL
     */
    mediumId?: number;

    /**
     * Interface language
     */
    lang?: string;

    /**
     * filter by "note" operation type: null - all 0 - no "note" operations 1 - only "note" operations
     */
    isNote?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<ClientSupportApiContractHistoryItem>>> {

    const rb = new RequestBuilder(this.rootUrl, SupportService.ApiSupportClientsIdHistoryGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('start', params.start, {});
      rb.query('end', params.end, {});
      rb.query('otype', params.otype, {});
      rb.query('utype', params.utype, {});
      rb.query('paymethodcode', params.paymethodcode, {});
      rb.query('mediumId', params.mediumId, {});
      rb.query('lang', params.lang, {});
      rb.query('isNote', params.isNote, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ClientSupportApiContractHistoryItem>>;
      })
    );
  }

  /**
   * Obtaining the history of the Client's operations.
   *
   * ### Claim
   *  boservice.support.getclienthistory
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSupportClientsIdHistoryGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportClientsIdHistoryGet$Plain(params: {

    /**
     * The customer ID
     */
    id: number;

    /**
     * Start date-time
     */
    start?: string;

    /**
     * End date-time
     */
    end?: string;

    /**
     * Operation type:
     *             0 - all
     *             1 - magazine,
     *             2 - replenishment
     */
    otype?: number;

    /**
     * Media type. NULL - all media types
     */
    utype?: number;

    /**
     * Payment method code. NULL - all method
     */
    paymethodcode?: number;

    /**
     * Media ID/NULL
     */
    mediumId?: number;

    /**
     * Interface language
     */
    lang?: string;

    /**
     * filter by "note" operation type: null - all 0 - no "note" operations 1 - only "note" operations
     */
    isNote?: number;
  },
  context?: HttpContext

): Observable<Array<ClientSupportApiContractHistoryItem>> {

    return this.apiSupportClientsIdHistoryGet$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<ClientSupportApiContractHistoryItem>>) => r.body as Array<ClientSupportApiContractHistoryItem>)
    );
  }

  /**
   * Obtaining the history of the Client's operations.
   *
   * ### Claim
   *  boservice.support.getclienthistory
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSupportClientsIdHistoryGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportClientsIdHistoryGet$Response(params: {

    /**
     * The customer ID
     */
    id: number;

    /**
     * Start date-time
     */
    start?: string;

    /**
     * End date-time
     */
    end?: string;

    /**
     * Operation type:
     *             0 - all
     *             1 - magazine,
     *             2 - replenishment
     */
    otype?: number;

    /**
     * Media type. NULL - all media types
     */
    utype?: number;

    /**
     * Payment method code. NULL - all method
     */
    paymethodcode?: number;

    /**
     * Media ID/NULL
     */
    mediumId?: number;

    /**
     * Interface language
     */
    lang?: string;

    /**
     * filter by "note" operation type: null - all 0 - no "note" operations 1 - only "note" operations
     */
    isNote?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<ClientSupportApiContractHistoryItem>>> {

    const rb = new RequestBuilder(this.rootUrl, SupportService.ApiSupportClientsIdHistoryGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('start', params.start, {});
      rb.query('end', params.end, {});
      rb.query('otype', params.otype, {});
      rb.query('utype', params.utype, {});
      rb.query('paymethodcode', params.paymethodcode, {});
      rb.query('mediumId', params.mediumId, {});
      rb.query('lang', params.lang, {});
      rb.query('isNote', params.isNote, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ClientSupportApiContractHistoryItem>>;
      })
    );
  }

  /**
   * Obtaining the history of the Client's operations.
   *
   * ### Claim
   *  boservice.support.getclienthistory
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSupportClientsIdHistoryGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportClientsIdHistoryGet(params: {

    /**
     * The customer ID
     */
    id: number;

    /**
     * Start date-time
     */
    start?: string;

    /**
     * End date-time
     */
    end?: string;

    /**
     * Operation type:
     *             0 - all
     *             1 - magazine,
     *             2 - replenishment
     */
    otype?: number;

    /**
     * Media type. NULL - all media types
     */
    utype?: number;

    /**
     * Payment method code. NULL - all method
     */
    paymethodcode?: number;

    /**
     * Media ID/NULL
     */
    mediumId?: number;

    /**
     * Interface language
     */
    lang?: string;

    /**
     * filter by "note" operation type: null - all 0 - no "note" operations 1 - only "note" operations
     */
    isNote?: number;
  },
  context?: HttpContext

): Observable<Array<ClientSupportApiContractHistoryItem>> {

    return this.apiSupportClientsIdHistoryGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<ClientSupportApiContractHistoryItem>>) => r.body as Array<ClientSupportApiContractHistoryItem>)
    );
  }

  /**
   * Path part for operation apiSupportClientsIdCardMovePost
   */
  static readonly ApiSupportClientsIdCardMovePostPath = '/api/Support/clients/{id}/card/move';

  /**
   * Transferring a ticket to another carrier.
   *
   * ### Claim
   *  boservice.support.cardmove
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSupportClientsIdCardMovePost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSupportClientsIdCardMovePost$Response(params: {
    id: number;

    /**
     * Interface language
     */
    lang?: string;
    body?: ClientSupportApiContractMoveUid
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, SupportService.ApiSupportClientsIdCardMovePostPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('lang', params.lang, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Transferring a ticket to another carrier.
   *
   * ### Claim
   *  boservice.support.cardmove
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSupportClientsIdCardMovePost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSupportClientsIdCardMovePost(params: {
    id: number;

    /**
     * Interface language
     */
    lang?: string;
    body?: ClientSupportApiContractMoveUid
  },
  context?: HttpContext

): Observable<void> {

    return this.apiSupportClientsIdCardMovePost$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiSupportUidsIdZonesGet
   */
  static readonly ApiSupportUidsIdZonesGetPath = '/api/Support/uids/{id}/zones';

  /**
   * Getting a list of control scope statuses for a media.
   *
   * ### Claim
   *  boservice.support.getzones
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSupportUidsIdZonesGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportUidsIdZonesGet$Plain$Response(params: {

    /**
     * Media ID
     */
    id: number;

    /**
     * Interface language
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<ClientSupportApiContractZone>>> {

    const rb = new RequestBuilder(this.rootUrl, SupportService.ApiSupportUidsIdZonesGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('lang', params.lang, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ClientSupportApiContractZone>>;
      })
    );
  }

  /**
   * Getting a list of control scope statuses for a media.
   *
   * ### Claim
   *  boservice.support.getzones
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSupportUidsIdZonesGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportUidsIdZonesGet$Plain(params: {

    /**
     * Media ID
     */
    id: number;

    /**
     * Interface language
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<Array<ClientSupportApiContractZone>> {

    return this.apiSupportUidsIdZonesGet$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<ClientSupportApiContractZone>>) => r.body as Array<ClientSupportApiContractZone>)
    );
  }

  /**
   * Getting a list of control scope statuses for a media.
   *
   * ### Claim
   *  boservice.support.getzones
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSupportUidsIdZonesGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportUidsIdZonesGet$Response(params: {

    /**
     * Media ID
     */
    id: number;

    /**
     * Interface language
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<ClientSupportApiContractZone>>> {

    const rb = new RequestBuilder(this.rootUrl, SupportService.ApiSupportUidsIdZonesGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('lang', params.lang, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ClientSupportApiContractZone>>;
      })
    );
  }

  /**
   * Getting a list of control scope statuses for a media.
   *
   * ### Claim
   *  boservice.support.getzones
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSupportUidsIdZonesGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportUidsIdZonesGet(params: {

    /**
     * Media ID
     */
    id: number;

    /**
     * Interface language
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<Array<ClientSupportApiContractZone>> {

    return this.apiSupportUidsIdZonesGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<ClientSupportApiContractZone>>) => r.body as Array<ClientSupportApiContractZone>)
    );
  }

  /**
   * Path part for operation apiSupportUidsTypesGet
   */
  static readonly ApiSupportUidsTypesGetPath = '/api/Support/uids/types';

  /**
   * Getting a list of identifier types (carriers).
   *
   * ### Claim
   *  boservice.support.gettypes
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSupportUidsTypesGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportUidsTypesGet$Plain$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<ClientSupportApiContractMediumType>>> {

    const rb = new RequestBuilder(this.rootUrl, SupportService.ApiSupportUidsTypesGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ClientSupportApiContractMediumType>>;
      })
    );
  }

  /**
   * Getting a list of identifier types (carriers).
   *
   * ### Claim
   *  boservice.support.gettypes
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSupportUidsTypesGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportUidsTypesGet$Plain(params?: {

    /**
     * Interface language
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<Array<ClientSupportApiContractMediumType>> {

    return this.apiSupportUidsTypesGet$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<ClientSupportApiContractMediumType>>) => r.body as Array<ClientSupportApiContractMediumType>)
    );
  }

  /**
   * Getting a list of identifier types (carriers).
   *
   * ### Claim
   *  boservice.support.gettypes
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSupportUidsTypesGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportUidsTypesGet$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<ClientSupportApiContractMediumType>>> {

    const rb = new RequestBuilder(this.rootUrl, SupportService.ApiSupportUidsTypesGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ClientSupportApiContractMediumType>>;
      })
    );
  }

  /**
   * Getting a list of identifier types (carriers).
   *
   * ### Claim
   *  boservice.support.gettypes
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSupportUidsTypesGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportUidsTypesGet(params?: {

    /**
     * Interface language
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<Array<ClientSupportApiContractMediumType>> {

    return this.apiSupportUidsTypesGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<ClientSupportApiContractMediumType>>) => r.body as Array<ClientSupportApiContractMediumType>)
    );
  }

  /**
   * Path part for operation apiSupportClientsIdUidsUidTicketsGet
   */
  static readonly ApiSupportClientsIdUidsUidTicketsGetPath = '/api/Support/clients/{id}/uids/{uid}/tickets';

  /**
   * Getting a list of travel tickets (subscriptions) for the carrier.
   *
   * ### Claim
   *  boservice.support.gettickets
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSupportClientsIdUidsUidTicketsGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportClientsIdUidsUidTicketsGet$Plain$Response(params: {

    /**
     * The customer ID
     */
    id: number;

    /**
     * Media ID / NULL
     */
    uid: number;

    /**
     * Interface language
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<ClientSupportApiContractClientTicket>>> {

    const rb = new RequestBuilder(this.rootUrl, SupportService.ApiSupportClientsIdUidsUidTicketsGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('uid', params.uid, {});
      rb.query('lang', params.lang, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ClientSupportApiContractClientTicket>>;
      })
    );
  }

  /**
   * Getting a list of travel tickets (subscriptions) for the carrier.
   *
   * ### Claim
   *  boservice.support.gettickets
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSupportClientsIdUidsUidTicketsGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportClientsIdUidsUidTicketsGet$Plain(params: {

    /**
     * The customer ID
     */
    id: number;

    /**
     * Media ID / NULL
     */
    uid: number;

    /**
     * Interface language
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<Array<ClientSupportApiContractClientTicket>> {

    return this.apiSupportClientsIdUidsUidTicketsGet$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<ClientSupportApiContractClientTicket>>) => r.body as Array<ClientSupportApiContractClientTicket>)
    );
  }

  /**
   * Getting a list of travel tickets (subscriptions) for the carrier.
   *
   * ### Claim
   *  boservice.support.gettickets
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSupportClientsIdUidsUidTicketsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportClientsIdUidsUidTicketsGet$Response(params: {

    /**
     * The customer ID
     */
    id: number;

    /**
     * Media ID / NULL
     */
    uid: number;

    /**
     * Interface language
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<ClientSupportApiContractClientTicket>>> {

    const rb = new RequestBuilder(this.rootUrl, SupportService.ApiSupportClientsIdUidsUidTicketsGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('uid', params.uid, {});
      rb.query('lang', params.lang, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ClientSupportApiContractClientTicket>>;
      })
    );
  }

  /**
   * Getting a list of travel tickets (subscriptions) for the carrier.
   *
   * ### Claim
   *  boservice.support.gettickets
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSupportClientsIdUidsUidTicketsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportClientsIdUidsUidTicketsGet(params: {

    /**
     * The customer ID
     */
    id: number;

    /**
     * Media ID / NULL
     */
    uid: number;

    /**
     * Interface language
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<Array<ClientSupportApiContractClientTicket>> {

    return this.apiSupportClientsIdUidsUidTicketsGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<ClientSupportApiContractClientTicket>>) => r.body as Array<ClientSupportApiContractClientTicket>)
    );
  }

  /**
   * Path part for operation apiSupportClientsIdUidsValidatePost
   */
  static readonly ApiSupportClientsIdUidsValidatePostPath = '/api/Support/clients/{id}/uids/validate';

  /**
   * Checking the availability of media in the Client's cabinet.
   *
   * ### Claim
   *  boservice.support.validate
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSupportClientsIdUidsValidatePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSupportClientsIdUidsValidatePost$Plain$Response(params: {

    /**
     * Customer ID
     */
    id: number;

    /**
     * Interface language
     */
    lang?: string;
    body?: ClientSupportApiContractUidValid
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ClientSupportApiContractValidateResult>> {

    const rb = new RequestBuilder(this.rootUrl, SupportService.ApiSupportClientsIdUidsValidatePostPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('lang', params.lang, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClientSupportApiContractValidateResult>;
      })
    );
  }

  /**
   * Checking the availability of media in the Client's cabinet.
   *
   * ### Claim
   *  boservice.support.validate
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSupportClientsIdUidsValidatePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSupportClientsIdUidsValidatePost$Plain(params: {

    /**
     * Customer ID
     */
    id: number;

    /**
     * Interface language
     */
    lang?: string;
    body?: ClientSupportApiContractUidValid
  },
  context?: HttpContext

): Observable<ClientSupportApiContractValidateResult> {

    return this.apiSupportClientsIdUidsValidatePost$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<ClientSupportApiContractValidateResult>) => r.body as ClientSupportApiContractValidateResult)
    );
  }

  /**
   * Checking the availability of media in the Client's cabinet.
   *
   * ### Claim
   *  boservice.support.validate
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSupportClientsIdUidsValidatePost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSupportClientsIdUidsValidatePost$Response(params: {

    /**
     * Customer ID
     */
    id: number;

    /**
     * Interface language
     */
    lang?: string;
    body?: ClientSupportApiContractUidValid
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ClientSupportApiContractValidateResult>> {

    const rb = new RequestBuilder(this.rootUrl, SupportService.ApiSupportClientsIdUidsValidatePostPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('lang', params.lang, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClientSupportApiContractValidateResult>;
      })
    );
  }

  /**
   * Checking the availability of media in the Client's cabinet.
   *
   * ### Claim
   *  boservice.support.validate
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSupportClientsIdUidsValidatePost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSupportClientsIdUidsValidatePost(params: {

    /**
     * Customer ID
     */
    id: number;

    /**
     * Interface language
     */
    lang?: string;
    body?: ClientSupportApiContractUidValid
  },
  context?: HttpContext

): Observable<ClientSupportApiContractValidateResult> {

    return this.apiSupportClientsIdUidsValidatePost$Response(params,context).pipe(
      map((r: StrictHttpResponse<ClientSupportApiContractValidateResult>) => r.body as ClientSupportApiContractValidateResult)
    );
  }

  /**
   * Path part for operation apiSupportPaymethodsGet
   */
  static readonly ApiSupportPaymethodsGetPath = '/api/Support/paymethods';

  /**
   * Obtaining a directory of ways to replenish a client's account.
   *
   * ### Claim
   *  boservice.support.paymethods
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSupportPaymethodsGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportPaymethodsGet$Plain$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<ClientSupportApiContractClientPaymentMethod>>> {

    const rb = new RequestBuilder(this.rootUrl, SupportService.ApiSupportPaymethodsGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ClientSupportApiContractClientPaymentMethod>>;
      })
    );
  }

  /**
   * Obtaining a directory of ways to replenish a client's account.
   *
   * ### Claim
   *  boservice.support.paymethods
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSupportPaymethodsGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportPaymethodsGet$Plain(params?: {

    /**
     * Interface language
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<Array<ClientSupportApiContractClientPaymentMethod>> {

    return this.apiSupportPaymethodsGet$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<ClientSupportApiContractClientPaymentMethod>>) => r.body as Array<ClientSupportApiContractClientPaymentMethod>)
    );
  }

  /**
   * Obtaining a directory of ways to replenish a client's account.
   *
   * ### Claim
   *  boservice.support.paymethods
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSupportPaymethodsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportPaymethodsGet$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<ClientSupportApiContractClientPaymentMethod>>> {

    const rb = new RequestBuilder(this.rootUrl, SupportService.ApiSupportPaymethodsGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ClientSupportApiContractClientPaymentMethod>>;
      })
    );
  }

  /**
   * Obtaining a directory of ways to replenish a client's account.
   *
   * ### Claim
   *  boservice.support.paymethods
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSupportPaymethodsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportPaymethodsGet(params?: {

    /**
     * Interface language
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<Array<ClientSupportApiContractClientPaymentMethod>> {

    return this.apiSupportPaymethodsGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<ClientSupportApiContractClientPaymentMethod>>) => r.body as Array<ClientSupportApiContractClientPaymentMethod>)
    );
  }

  /**
   * Path part for operation apiSupportClientsIdTicketTrnIdGet
   */
  static readonly ApiSupportClientsIdTicketTrnIdGetPath = '/api/Support/clients/{id}/ticket/{trnId}';

  /**
   * Obtaining ticket data by entry from the history of operations.
   *
   * ### Claim
   *  boservice.support.getticket
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSupportClientsIdTicketTrnIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportClientsIdTicketTrnIdGet$Plain$Response(params: {

    /**
     * The customer ID
     */
    id: number;

    /**
     * Operation ID
     */
    trnId: number;
    delayedPass?: number;

    /**
     * Interface language
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ClientSupportApiContractTicketInfo>> {

    const rb = new RequestBuilder(this.rootUrl, SupportService.ApiSupportClientsIdTicketTrnIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('trnId', params.trnId, {});
      rb.query('delayedPass', params.delayedPass, {});
      rb.query('lang', params.lang, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClientSupportApiContractTicketInfo>;
      })
    );
  }

  /**
   * Obtaining ticket data by entry from the history of operations.
   *
   * ### Claim
   *  boservice.support.getticket
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSupportClientsIdTicketTrnIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportClientsIdTicketTrnIdGet$Plain(params: {

    /**
     * The customer ID
     */
    id: number;

    /**
     * Operation ID
     */
    trnId: number;
    delayedPass?: number;

    /**
     * Interface language
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<ClientSupportApiContractTicketInfo> {

    return this.apiSupportClientsIdTicketTrnIdGet$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<ClientSupportApiContractTicketInfo>) => r.body as ClientSupportApiContractTicketInfo)
    );
  }

  /**
   * Obtaining ticket data by entry from the history of operations.
   *
   * ### Claim
   *  boservice.support.getticket
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSupportClientsIdTicketTrnIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportClientsIdTicketTrnIdGet$Response(params: {

    /**
     * The customer ID
     */
    id: number;

    /**
     * Operation ID
     */
    trnId: number;
    delayedPass?: number;

    /**
     * Interface language
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ClientSupportApiContractTicketInfo>> {

    const rb = new RequestBuilder(this.rootUrl, SupportService.ApiSupportClientsIdTicketTrnIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('trnId', params.trnId, {});
      rb.query('delayedPass', params.delayedPass, {});
      rb.query('lang', params.lang, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClientSupportApiContractTicketInfo>;
      })
    );
  }

  /**
   * Obtaining ticket data by entry from the history of operations.
   *
   * ### Claim
   *  boservice.support.getticket
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSupportClientsIdTicketTrnIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportClientsIdTicketTrnIdGet(params: {

    /**
     * The customer ID
     */
    id: number;

    /**
     * Operation ID
     */
    trnId: number;
    delayedPass?: number;

    /**
     * Interface language
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<ClientSupportApiContractTicketInfo> {

    return this.apiSupportClientsIdTicketTrnIdGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<ClientSupportApiContractTicketInfo>) => r.body as ClientSupportApiContractTicketInfo)
    );
  }

  /**
   * Path part for operation apiSupportClientsIdBarcodeGet
   */
  static readonly ApiSupportClientsIdBarcodeGetPath = '/api/Support/clients/{id}/barcode';

  /**
   * Obtaining barcode change history.
   *
   * ### Claim
   *  boservice.support.listidchange
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSupportClientsIdBarcodeGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportClientsIdBarcodeGet$Plain$Response(params: {

    /**
     * The customer ID
     */
    id: number;

    /**
     * Start datetime/NULL
     */
    startDate?: string;

    /**
     * End datetime/NULL
     */
    endDate?: string;

    /**
     * Interface language
     */
    lang?: string;

    /**
     * Media ID
     */
    mediumId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<ClientSupportApiContractClientIdChange>>> {

    const rb = new RequestBuilder(this.rootUrl, SupportService.ApiSupportClientsIdBarcodeGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('startDate', params.startDate, {});
      rb.query('endDate', params.endDate, {});
      rb.query('lang', params.lang, {});
      rb.query('mediumId', params.mediumId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ClientSupportApiContractClientIdChange>>;
      })
    );
  }

  /**
   * Obtaining barcode change history.
   *
   * ### Claim
   *  boservice.support.listidchange
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSupportClientsIdBarcodeGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportClientsIdBarcodeGet$Plain(params: {

    /**
     * The customer ID
     */
    id: number;

    /**
     * Start datetime/NULL
     */
    startDate?: string;

    /**
     * End datetime/NULL
     */
    endDate?: string;

    /**
     * Interface language
     */
    lang?: string;

    /**
     * Media ID
     */
    mediumId?: number;
  },
  context?: HttpContext

): Observable<Array<ClientSupportApiContractClientIdChange>> {

    return this.apiSupportClientsIdBarcodeGet$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<ClientSupportApiContractClientIdChange>>) => r.body as Array<ClientSupportApiContractClientIdChange>)
    );
  }

  /**
   * Obtaining barcode change history.
   *
   * ### Claim
   *  boservice.support.listidchange
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSupportClientsIdBarcodeGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportClientsIdBarcodeGet$Response(params: {

    /**
     * The customer ID
     */
    id: number;

    /**
     * Start datetime/NULL
     */
    startDate?: string;

    /**
     * End datetime/NULL
     */
    endDate?: string;

    /**
     * Interface language
     */
    lang?: string;

    /**
     * Media ID
     */
    mediumId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<ClientSupportApiContractClientIdChange>>> {

    const rb = new RequestBuilder(this.rootUrl, SupportService.ApiSupportClientsIdBarcodeGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('startDate', params.startDate, {});
      rb.query('endDate', params.endDate, {});
      rb.query('lang', params.lang, {});
      rb.query('mediumId', params.mediumId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ClientSupportApiContractClientIdChange>>;
      })
    );
  }

  /**
   * Obtaining barcode change history.
   *
   * ### Claim
   *  boservice.support.listidchange
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSupportClientsIdBarcodeGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportClientsIdBarcodeGet(params: {

    /**
     * The customer ID
     */
    id: number;

    /**
     * Start datetime/NULL
     */
    startDate?: string;

    /**
     * End datetime/NULL
     */
    endDate?: string;

    /**
     * Interface language
     */
    lang?: string;

    /**
     * Media ID
     */
    mediumId?: number;
  },
  context?: HttpContext

): Observable<Array<ClientSupportApiContractClientIdChange>> {

    return this.apiSupportClientsIdBarcodeGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<ClientSupportApiContractClientIdChange>>) => r.body as Array<ClientSupportApiContractClientIdChange>)
    );
  }

  /**
   * Path part for operation apiSupportSetClientPrivPost
   */
  static readonly ApiSupportSetClientPrivPostPath = '/api/Support/SetClientPriv';

  /**
   * setting the VRE customer benefit category.
   *
   * ### Claim
   *  boservice.support.setclientpriv
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSupportSetClientPrivPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSupportSetClientPrivPost$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;
    body?: ClientSupportApiContractSetClientPrivRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, SupportService.ApiSupportSetClientPrivPostPath, 'post');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * setting the VRE customer benefit category.
   *
   * ### Claim
   *  boservice.support.setclientpriv
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSupportSetClientPrivPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSupportSetClientPrivPost(params?: {

    /**
     * Interface language
     */
    lang?: string;
    body?: ClientSupportApiContractSetClientPrivRequest
  },
  context?: HttpContext

): Observable<void> {

    return this.apiSupportSetClientPrivPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiSupportSetClientDataPost
   */
  static readonly ApiSupportSetClientDataPostPath = '/api/Support/SetClientData';

  /**
   * editing customer data.
   *
   * ### Claim
   *  boservice.support.setclientdata
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSupportSetClientDataPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSupportSetClientDataPost$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * customer data
     */
    body?: ClientSupportApiContractSetClientDataRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, SupportService.ApiSupportSetClientDataPostPath, 'post');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * editing customer data.
   *
   * ### Claim
   *  boservice.support.setclientdata
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSupportSetClientDataPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSupportSetClientDataPost(params?: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * customer data
     */
    body?: ClientSupportApiContractSetClientDataRequest
  },
  context?: HttpContext

): Observable<void> {

    return this.apiSupportSetClientDataPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiSupportClientsIdTicketsGet
   */
  static readonly ApiSupportClientsIdTicketsGetPath = '/api/Support/clients/{id}/tickets';

  /**
   * ticket list.
   *
   * ### Claim
   *  boservice.support.listtickettotal
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSupportClientsIdTicketsGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportClientsIdTicketsGet$Plain$Response(params: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * customer id
     */
    id: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<ClientSupportApiContractTicketTotal>>> {

    const rb = new RequestBuilder(this.rootUrl, SupportService.ApiSupportClientsIdTicketsGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ClientSupportApiContractTicketTotal>>;
      })
    );
  }

  /**
   * ticket list.
   *
   * ### Claim
   *  boservice.support.listtickettotal
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSupportClientsIdTicketsGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportClientsIdTicketsGet$Plain(params: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * customer id
     */
    id: number;
  },
  context?: HttpContext

): Observable<Array<ClientSupportApiContractTicketTotal>> {

    return this.apiSupportClientsIdTicketsGet$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<ClientSupportApiContractTicketTotal>>) => r.body as Array<ClientSupportApiContractTicketTotal>)
    );
  }

  /**
   * ticket list.
   *
   * ### Claim
   *  boservice.support.listtickettotal
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSupportClientsIdTicketsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportClientsIdTicketsGet$Response(params: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * customer id
     */
    id: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<ClientSupportApiContractTicketTotal>>> {

    const rb = new RequestBuilder(this.rootUrl, SupportService.ApiSupportClientsIdTicketsGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ClientSupportApiContractTicketTotal>>;
      })
    );
  }

  /**
   * ticket list.
   *
   * ### Claim
   *  boservice.support.listtickettotal
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSupportClientsIdTicketsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportClientsIdTicketsGet(params: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * customer id
     */
    id: number;
  },
  context?: HttpContext

): Observable<Array<ClientSupportApiContractTicketTotal>> {

    return this.apiSupportClientsIdTicketsGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<ClientSupportApiContractTicketTotal>>) => r.body as Array<ClientSupportApiContractTicketTotal>)
    );
  }

  /**
   * Path part for operation apiSupportGetPtTicketDataGet
   */
  static readonly ApiSupportGetPtTicketDataGetPath = '/api/Support/GetPtTicketData';

  /**
   * printed ticket check.
   *
   * ### Claim
   *  boservice.support.getptticketdata
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSupportGetPtTicketDataGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportGetPtTicketDataGet$Plain$Response(params?: {
    lang?: string;
    series?: string;
    num?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ClientSupportApiContractGetPtTicketDataResponse>> {

    const rb = new RequestBuilder(this.rootUrl, SupportService.ApiSupportGetPtTicketDataGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('series', params.series, {});
      rb.query('num', params.num, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClientSupportApiContractGetPtTicketDataResponse>;
      })
    );
  }

  /**
   * printed ticket check.
   *
   * ### Claim
   *  boservice.support.getptticketdata
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSupportGetPtTicketDataGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportGetPtTicketDataGet$Plain(params?: {
    lang?: string;
    series?: string;
    num?: string;
  },
  context?: HttpContext

): Observable<ClientSupportApiContractGetPtTicketDataResponse> {

    return this.apiSupportGetPtTicketDataGet$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<ClientSupportApiContractGetPtTicketDataResponse>) => r.body as ClientSupportApiContractGetPtTicketDataResponse)
    );
  }

  /**
   * printed ticket check.
   *
   * ### Claim
   *  boservice.support.getptticketdata
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSupportGetPtTicketDataGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportGetPtTicketDataGet$Response(params?: {
    lang?: string;
    series?: string;
    num?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ClientSupportApiContractGetPtTicketDataResponse>> {

    const rb = new RequestBuilder(this.rootUrl, SupportService.ApiSupportGetPtTicketDataGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('series', params.series, {});
      rb.query('num', params.num, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClientSupportApiContractGetPtTicketDataResponse>;
      })
    );
  }

  /**
   * printed ticket check.
   *
   * ### Claim
   *  boservice.support.getptticketdata
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSupportGetPtTicketDataGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportGetPtTicketDataGet(params?: {
    lang?: string;
    series?: string;
    num?: string;
  },
  context?: HttpContext

): Observable<ClientSupportApiContractGetPtTicketDataResponse> {

    return this.apiSupportGetPtTicketDataGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<ClientSupportApiContractGetPtTicketDataResponse>) => r.body as ClientSupportApiContractGetPtTicketDataResponse)
    );
  }

  /**
   * Path part for operation apiSupportAddPushMsgPost
   */
  static readonly ApiSupportAddPushMsgPostPath = '/api/Support/AddPushMsg';

  /**
   * Sending an arbitrary PUSH message.
   *
   * Sending an arbitrary PUSH message to one or all clients.
   * Records the event in the audit and message history of the client/all clients.
   * ### Claim
   *  boservice.support.addpushmsg
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSupportAddPushMsgPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSupportAddPushMsgPost$Response(params?: {

    /**
     * * Interface language
     */
    lang?: string;
    body?: ClientSupportApiContractAddPushMsgRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, SupportService.ApiSupportAddPushMsgPostPath, 'post');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Sending an arbitrary PUSH message.
   *
   * Sending an arbitrary PUSH message to one or all clients.
   * Records the event in the audit and message history of the client/all clients.
   * ### Claim
   *  boservice.support.addpushmsg
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSupportAddPushMsgPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSupportAddPushMsgPost(params?: {

    /**
     * * Interface language
     */
    lang?: string;
    body?: ClientSupportApiContractAddPushMsgRequest
  },
  context?: HttpContext

): Observable<void> {

    return this.apiSupportAddPushMsgPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiSupportRemoveIdDelete
   */
  static readonly ApiSupportRemoveIdDeletePath = '/api/Support/RemoveId';

  /**
   * Removing client media.
   *
   * ### Claim
   *  boservice.support.removeid
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSupportRemoveIdDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportRemoveIdDelete$Response(params?: {

    /**
     * * Interface language
     */
    lang?: string;

    /**
     * * customer id
     */
    client?: number;

    /**
     * * media id
     */
    mediumId?: number;

    /**
     * note from support operator
     */
    note?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, SupportService.ApiSupportRemoveIdDeletePath, 'delete');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('client', params.client, {});
      rb.query('mediumId', params.mediumId, {});
      rb.query('note', params.note, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Removing client media.
   *
   * ### Claim
   *  boservice.support.removeid
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSupportRemoveIdDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportRemoveIdDelete(params?: {

    /**
     * * Interface language
     */
    lang?: string;

    /**
     * * customer id
     */
    client?: number;

    /**
     * * media id
     */
    mediumId?: number;

    /**
     * note from support operator
     */
    note?: string;
  },
  context?: HttpContext

): Observable<void> {

    return this.apiSupportRemoveIdDelete$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiSupportListOpNoteGet
   */
  static readonly ApiSupportListOpNoteGetPath = '/api/Support/ListOpNote';

  /**
   * Getting a list of comments for a support operation or a Client transaction.
   *
   * ### Claim
   *  boservice.support.listopnote
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSupportListOpNoteGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportListOpNoteGet$Plain$Response(params?: {

    /**
     * * Interface language
     */
    lang?: string;

    /**
     * * customer id
     */
    client?: number;

    /**
     * * operation id
     */
    trnId?: number;

    /**
     * * type of transaction
     */
    opType?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<ClientSupportApiContractNotesListOpNoteResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, SupportService.ApiSupportListOpNoteGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('client', params.client, {});
      rb.query('trnId', params.trnId, {});
      rb.query('opType', params.opType, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ClientSupportApiContractNotesListOpNoteResponse>>;
      })
    );
  }

  /**
   * Getting a list of comments for a support operation or a Client transaction.
   *
   * ### Claim
   *  boservice.support.listopnote
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSupportListOpNoteGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportListOpNoteGet$Plain(params?: {

    /**
     * * Interface language
     */
    lang?: string;

    /**
     * * customer id
     */
    client?: number;

    /**
     * * operation id
     */
    trnId?: number;

    /**
     * * type of transaction
     */
    opType?: number;
  },
  context?: HttpContext

): Observable<Array<ClientSupportApiContractNotesListOpNoteResponse>> {

    return this.apiSupportListOpNoteGet$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<ClientSupportApiContractNotesListOpNoteResponse>>) => r.body as Array<ClientSupportApiContractNotesListOpNoteResponse>)
    );
  }

  /**
   * Getting a list of comments for a support operation or a Client transaction.
   *
   * ### Claim
   *  boservice.support.listopnote
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSupportListOpNoteGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportListOpNoteGet$Response(params?: {

    /**
     * * Interface language
     */
    lang?: string;

    /**
     * * customer id
     */
    client?: number;

    /**
     * * operation id
     */
    trnId?: number;

    /**
     * * type of transaction
     */
    opType?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<ClientSupportApiContractNotesListOpNoteResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, SupportService.ApiSupportListOpNoteGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('client', params.client, {});
      rb.query('trnId', params.trnId, {});
      rb.query('opType', params.opType, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ClientSupportApiContractNotesListOpNoteResponse>>;
      })
    );
  }

  /**
   * Getting a list of comments for a support operation or a Client transaction.
   *
   * ### Claim
   *  boservice.support.listopnote
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSupportListOpNoteGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportListOpNoteGet(params?: {

    /**
     * * Interface language
     */
    lang?: string;

    /**
     * * customer id
     */
    client?: number;

    /**
     * * operation id
     */
    trnId?: number;

    /**
     * * type of transaction
     */
    opType?: number;
  },
  context?: HttpContext

): Observable<Array<ClientSupportApiContractNotesListOpNoteResponse>> {

    return this.apiSupportListOpNoteGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<ClientSupportApiContractNotesListOpNoteResponse>>) => r.body as Array<ClientSupportApiContractNotesListOpNoteResponse>)
    );
  }

  /**
   * Path part for operation apiSupportAddOpNotePost
   */
  static readonly ApiSupportAddOpNotePostPath = '/api/Support/AddOpNote';

  /**
   * Creating a comment for a Client transaction or support operation.
   *
   * For a client transaction: if there is no entry in supp_operation, then create one
   * ### Claim
   *  boservice.support.addopnote
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSupportAddOpNotePost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSupportAddOpNotePost$Response(params?: {

    /**
     * * Interface language
     */
    lang?: string;
    body?: ClientSupportApiContractNotesAddOpNoteRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, SupportService.ApiSupportAddOpNotePostPath, 'post');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Creating a comment for a Client transaction or support operation.
   *
   * For a client transaction: if there is no entry in supp_operation, then create one
   * ### Claim
   *  boservice.support.addopnote
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSupportAddOpNotePost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSupportAddOpNotePost(params?: {

    /**
     * * Interface language
     */
    lang?: string;
    body?: ClientSupportApiContractNotesAddOpNoteRequest
  },
  context?: HttpContext

): Observable<void> {

    return this.apiSupportAddOpNotePost$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiSupportAddSupportNotePost
   */
  static readonly ApiSupportAddSupportNotePostPath = '/api/Support/AddSupportNote';

  /**
   * Creates a new operation of type "Note".
   *
   * ### Claim
   *  boservice.support.addsupportnote
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSupportAddSupportNotePost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSupportAddSupportNotePost$Response(params?: {

    /**
     * * Interface language
     */
    lang?: string;
    body?: ClientSupportApiContractNotesAddSupportNoteRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, SupportService.ApiSupportAddSupportNotePostPath, 'post');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Creates a new operation of type "Note".
   *
   * ### Claim
   *  boservice.support.addsupportnote
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSupportAddSupportNotePost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSupportAddSupportNotePost(params?: {

    /**
     * * Interface language
     */
    lang?: string;
    body?: ClientSupportApiContractNotesAddSupportNoteRequest
  },
  context?: HttpContext

): Observable<void> {

    return this.apiSupportAddSupportNotePost$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiSupportListTicketServiceGet
   */
  static readonly ApiSupportListTicketServiceGetPath = '/api/Support/ListTicketService';

  /**
   * List of services for prepaid tickets.
   *
   * Getting a list of services for prepaid tickets
   * ### Claim
   *  boservice.support.listticketservice
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSupportListTicketServiceGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportListTicketServiceGet$Plain$Response(params?: {

    /**
     * * language code
     */
    lang?: string;

    /**
     * * agency (pta) id
     */
    trmId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<ClientSupportApiContractCourtesyListTicketServiceResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, SupportService.ApiSupportListTicketServiceGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('trmId', params.trmId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ClientSupportApiContractCourtesyListTicketServiceResponse>>;
      })
    );
  }

  /**
   * List of services for prepaid tickets.
   *
   * Getting a list of services for prepaid tickets
   * ### Claim
   *  boservice.support.listticketservice
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSupportListTicketServiceGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportListTicketServiceGet$Plain(params?: {

    /**
     * * language code
     */
    lang?: string;

    /**
     * * agency (pta) id
     */
    trmId?: number;
  },
  context?: HttpContext

): Observable<Array<ClientSupportApiContractCourtesyListTicketServiceResponse>> {

    return this.apiSupportListTicketServiceGet$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<ClientSupportApiContractCourtesyListTicketServiceResponse>>) => r.body as Array<ClientSupportApiContractCourtesyListTicketServiceResponse>)
    );
  }

  /**
   * List of services for prepaid tickets.
   *
   * Getting a list of services for prepaid tickets
   * ### Claim
   *  boservice.support.listticketservice
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSupportListTicketServiceGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportListTicketServiceGet$Response(params?: {

    /**
     * * language code
     */
    lang?: string;

    /**
     * * agency (pta) id
     */
    trmId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<ClientSupportApiContractCourtesyListTicketServiceResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, SupportService.ApiSupportListTicketServiceGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('trmId', params.trmId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ClientSupportApiContractCourtesyListTicketServiceResponse>>;
      })
    );
  }

  /**
   * List of services for prepaid tickets.
   *
   * Getting a list of services for prepaid tickets
   * ### Claim
   *  boservice.support.listticketservice
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSupportListTicketServiceGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportListTicketServiceGet(params?: {

    /**
     * * language code
     */
    lang?: string;

    /**
     * * agency (pta) id
     */
    trmId?: number;
  },
  context?: HttpContext

): Observable<Array<ClientSupportApiContractCourtesyListTicketServiceResponse>> {

    return this.apiSupportListTicketServiceGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<ClientSupportApiContractCourtesyListTicketServiceResponse>>) => r.body as Array<ClientSupportApiContractCourtesyListTicketServiceResponse>)
    );
  }

  /**
   * Path part for operation apiSupportListRouteGet
   */
  static readonly ApiSupportListRouteGetPath = '/api/Support/ListRoute';

  /**
   * List of routes.
   *
   * Getting routes list for PP tickets with differential cost
   * ### Claim
   *  boservice.support.listroute
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSupportListRouteGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportListRouteGet$Plain$Response(params?: {

    /**
     * * language code
     */
    lang?: string;

    /**
     * * service id
     */
    srvId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<ClientSupportApiContractCourtesyListRouteResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, SupportService.ApiSupportListRouteGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('srvId', params.srvId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ClientSupportApiContractCourtesyListRouteResponse>>;
      })
    );
  }

  /**
   * List of routes.
   *
   * Getting routes list for PP tickets with differential cost
   * ### Claim
   *  boservice.support.listroute
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSupportListRouteGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportListRouteGet$Plain(params?: {

    /**
     * * language code
     */
    lang?: string;

    /**
     * * service id
     */
    srvId?: number;
  },
  context?: HttpContext

): Observable<Array<ClientSupportApiContractCourtesyListRouteResponse>> {

    return this.apiSupportListRouteGet$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<ClientSupportApiContractCourtesyListRouteResponse>>) => r.body as Array<ClientSupportApiContractCourtesyListRouteResponse>)
    );
  }

  /**
   * List of routes.
   *
   * Getting routes list for PP tickets with differential cost
   * ### Claim
   *  boservice.support.listroute
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSupportListRouteGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportListRouteGet$Response(params?: {

    /**
     * * language code
     */
    lang?: string;

    /**
     * * service id
     */
    srvId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<ClientSupportApiContractCourtesyListRouteResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, SupportService.ApiSupportListRouteGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('srvId', params.srvId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ClientSupportApiContractCourtesyListRouteResponse>>;
      })
    );
  }

  /**
   * List of routes.
   *
   * Getting routes list for PP tickets with differential cost
   * ### Claim
   *  boservice.support.listroute
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSupportListRouteGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportListRouteGet(params?: {

    /**
     * * language code
     */
    lang?: string;

    /**
     * * service id
     */
    srvId?: number;
  },
  context?: HttpContext

): Observable<Array<ClientSupportApiContractCourtesyListRouteResponse>> {

    return this.apiSupportListRouteGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<ClientSupportApiContractCourtesyListRouteResponse>>) => r.body as Array<ClientSupportApiContractCourtesyListRouteResponse>)
    );
  }

  /**
   * Path part for operation apiSupportListStopGet
   */
  static readonly ApiSupportListStopGetPath = '/api/Support/ListStop';

  /**
   * List of stops.
   *
   * Getting list of stops for a route
   * ### Claim
   *  boservice.support.liststop
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSupportListStopGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportListStopGet$Plain$Response(params?: {

    /**
     * * language code
     */
    lang?: string;

    /**
     * * route id
     */
    sdId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<ClientSupportApiContractCourtesyListStopResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, SupportService.ApiSupportListStopGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('sdId', params.sdId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ClientSupportApiContractCourtesyListStopResponse>>;
      })
    );
  }

  /**
   * List of stops.
   *
   * Getting list of stops for a route
   * ### Claim
   *  boservice.support.liststop
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSupportListStopGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportListStopGet$Plain(params?: {

    /**
     * * language code
     */
    lang?: string;

    /**
     * * route id
     */
    sdId?: number;
  },
  context?: HttpContext

): Observable<Array<ClientSupportApiContractCourtesyListStopResponse>> {

    return this.apiSupportListStopGet$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<ClientSupportApiContractCourtesyListStopResponse>>) => r.body as Array<ClientSupportApiContractCourtesyListStopResponse>)
    );
  }

  /**
   * List of stops.
   *
   * Getting list of stops for a route
   * ### Claim
   *  boservice.support.liststop
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSupportListStopGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportListStopGet$Response(params?: {

    /**
     * * language code
     */
    lang?: string;

    /**
     * * route id
     */
    sdId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<ClientSupportApiContractCourtesyListStopResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, SupportService.ApiSupportListStopGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('sdId', params.sdId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ClientSupportApiContractCourtesyListStopResponse>>;
      })
    );
  }

  /**
   * List of stops.
   *
   * Getting list of stops for a route
   * ### Claim
   *  boservice.support.liststop
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSupportListStopGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportListStopGet(params?: {

    /**
     * * language code
     */
    lang?: string;

    /**
     * * route id
     */
    sdId?: number;
  },
  context?: HttpContext

): Observable<Array<ClientSupportApiContractCourtesyListStopResponse>> {

    return this.apiSupportListStopGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<ClientSupportApiContractCourtesyListStopResponse>>) => r.body as Array<ClientSupportApiContractCourtesyListStopResponse>)
    );
  }

  /**
   * Path part for operation apiSupportListAvailTicketGet
   */
  static readonly ApiSupportListAvailTicketGetPath = '/api/Support/ListAvailTicket';

  /**
   * List of prepaid tickets.
   *
   * Getting a list of PP tariffs for a service
   * ### Claim
   *  boservice.support.listavailticket
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSupportListAvailTicketGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportListAvailTicketGet$Plain$Response(params?: {

    /**
     * * language code
     */
    lang?: string;

    /**
     * * service id
     */
    srvId?: number;

    /**
     * * agency (pta) id
     */
    trmId?: number;

    /**
     * origin stop id/null
     */
    stopFrom?: number;

    /**
     * destination stop id/null
     */
    stopTo?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<ClientSupportApiContractCourtesyListAvailTicketResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, SupportService.ApiSupportListAvailTicketGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('srvId', params.srvId, {});
      rb.query('trmId', params.trmId, {});
      rb.query('stopFrom', params.stopFrom, {});
      rb.query('stopTo', params.stopTo, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ClientSupportApiContractCourtesyListAvailTicketResponse>>;
      })
    );
  }

  /**
   * List of prepaid tickets.
   *
   * Getting a list of PP tariffs for a service
   * ### Claim
   *  boservice.support.listavailticket
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSupportListAvailTicketGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportListAvailTicketGet$Plain(params?: {

    /**
     * * language code
     */
    lang?: string;

    /**
     * * service id
     */
    srvId?: number;

    /**
     * * agency (pta) id
     */
    trmId?: number;

    /**
     * origin stop id/null
     */
    stopFrom?: number;

    /**
     * destination stop id/null
     */
    stopTo?: number;
  },
  context?: HttpContext

): Observable<Array<ClientSupportApiContractCourtesyListAvailTicketResponse>> {

    return this.apiSupportListAvailTicketGet$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<ClientSupportApiContractCourtesyListAvailTicketResponse>>) => r.body as Array<ClientSupportApiContractCourtesyListAvailTicketResponse>)
    );
  }

  /**
   * List of prepaid tickets.
   *
   * Getting a list of PP tariffs for a service
   * ### Claim
   *  boservice.support.listavailticket
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSupportListAvailTicketGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportListAvailTicketGet$Response(params?: {

    /**
     * * language code
     */
    lang?: string;

    /**
     * * service id
     */
    srvId?: number;

    /**
     * * agency (pta) id
     */
    trmId?: number;

    /**
     * origin stop id/null
     */
    stopFrom?: number;

    /**
     * destination stop id/null
     */
    stopTo?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<ClientSupportApiContractCourtesyListAvailTicketResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, SupportService.ApiSupportListAvailTicketGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('srvId', params.srvId, {});
      rb.query('trmId', params.trmId, {});
      rb.query('stopFrom', params.stopFrom, {});
      rb.query('stopTo', params.stopTo, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ClientSupportApiContractCourtesyListAvailTicketResponse>>;
      })
    );
  }

  /**
   * List of prepaid tickets.
   *
   * Getting a list of PP tariffs for a service
   * ### Claim
   *  boservice.support.listavailticket
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSupportListAvailTicketGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportListAvailTicketGet(params?: {

    /**
     * * language code
     */
    lang?: string;

    /**
     * * service id
     */
    srvId?: number;

    /**
     * * agency (pta) id
     */
    trmId?: number;

    /**
     * origin stop id/null
     */
    stopFrom?: number;

    /**
     * destination stop id/null
     */
    stopTo?: number;
  },
  context?: HttpContext

): Observable<Array<ClientSupportApiContractCourtesyListAvailTicketResponse>> {

    return this.apiSupportListAvailTicketGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<ClientSupportApiContractCourtesyListAvailTicketResponse>>) => r.body as Array<ClientSupportApiContractCourtesyListAvailTicketResponse>)
    );
  }

  /**
   * Path part for operation apiSupportAddTicketPost
   */
  static readonly ApiSupportAddTicketPostPath = '/api/Support/AddTicket';

  /**
   * Prepaid ticket grant.
   *
   * Grants prepaid ticket to a customer
   * ### Claim
   *  boservice.support.addticket
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSupportAddTicketPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSupportAddTicketPost$Response(params?: {

    /**
     * * language code
     */
    lang?: string;
    body?: ClientSupportApiContractCourtesyAddTicketRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, SupportService.ApiSupportAddTicketPostPath, 'post');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Prepaid ticket grant.
   *
   * Grants prepaid ticket to a customer
   * ### Claim
   *  boservice.support.addticket
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSupportAddTicketPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSupportAddTicketPost(params?: {

    /**
     * * language code
     */
    lang?: string;
    body?: ClientSupportApiContractCourtesyAddTicketRequest
  },
  context?: HttpContext

): Observable<void> {

    return this.apiSupportAddTicketPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiSupportListClientGet
   */
  static readonly ApiSupportListClientGetPath = '/api/Support/ListClient';

  /**
   * Find client.
   *
   * Find clients by phone number or e-mail.
   * Both phone number and e-mail are used for a substring search.
   * Search is performed with AND condition.
   * Output is limited by 10 records.
   * ### Claim
   *  boservice.support.listclient
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSupportListClientGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportListClientGet$Plain$Response(params?: {

    /**
     * * language code
     */
    lang?: string;

    /**
     * client's mobile number/null if not used. minimum length is 6 chars. for passed string '001234' value '001%234%' is used for search.
     */
    mobilePhone?: string;

    /**
     * client's e-mail/null if not used. minimum length is 3 chars (not including special characters). for passed string 'abc' value '%abc%' is used for search.
     */
    email?: string;

    /**
     * client id /null
     */
    clientId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<ClientSupportApiContractListClientResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, SupportService.ApiSupportListClientGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('mobilePhone', params.mobilePhone, {});
      rb.query('email', params.email, {});
      rb.query('clientId', params.clientId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ClientSupportApiContractListClientResponse>>;
      })
    );
  }

  /**
   * Find client.
   *
   * Find clients by phone number or e-mail.
   * Both phone number and e-mail are used for a substring search.
   * Search is performed with AND condition.
   * Output is limited by 10 records.
   * ### Claim
   *  boservice.support.listclient
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSupportListClientGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportListClientGet$Plain(params?: {

    /**
     * * language code
     */
    lang?: string;

    /**
     * client's mobile number/null if not used. minimum length is 6 chars. for passed string '001234' value '001%234%' is used for search.
     */
    mobilePhone?: string;

    /**
     * client's e-mail/null if not used. minimum length is 3 chars (not including special characters). for passed string 'abc' value '%abc%' is used for search.
     */
    email?: string;

    /**
     * client id /null
     */
    clientId?: number;
  },
  context?: HttpContext

): Observable<Array<ClientSupportApiContractListClientResponse>> {

    return this.apiSupportListClientGet$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<ClientSupportApiContractListClientResponse>>) => r.body as Array<ClientSupportApiContractListClientResponse>)
    );
  }

  /**
   * Find client.
   *
   * Find clients by phone number or e-mail.
   * Both phone number and e-mail are used for a substring search.
   * Search is performed with AND condition.
   * Output is limited by 10 records.
   * ### Claim
   *  boservice.support.listclient
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSupportListClientGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportListClientGet$Response(params?: {

    /**
     * * language code
     */
    lang?: string;

    /**
     * client's mobile number/null if not used. minimum length is 6 chars. for passed string '001234' value '001%234%' is used for search.
     */
    mobilePhone?: string;

    /**
     * client's e-mail/null if not used. minimum length is 3 chars (not including special characters). for passed string 'abc' value '%abc%' is used for search.
     */
    email?: string;

    /**
     * client id /null
     */
    clientId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<ClientSupportApiContractListClientResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, SupportService.ApiSupportListClientGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('mobilePhone', params.mobilePhone, {});
      rb.query('email', params.email, {});
      rb.query('clientId', params.clientId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ClientSupportApiContractListClientResponse>>;
      })
    );
  }

  /**
   * Find client.
   *
   * Find clients by phone number or e-mail.
   * Both phone number and e-mail are used for a substring search.
   * Search is performed with AND condition.
   * Output is limited by 10 records.
   * ### Claim
   *  boservice.support.listclient
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSupportListClientGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportListClientGet(params?: {

    /**
     * * language code
     */
    lang?: string;

    /**
     * client's mobile number/null if not used. minimum length is 6 chars. for passed string '001234' value '001%234%' is used for search.
     */
    mobilePhone?: string;

    /**
     * client's e-mail/null if not used. minimum length is 3 chars (not including special characters). for passed string 'abc' value '%abc%' is used for search.
     */
    email?: string;

    /**
     * client id /null
     */
    clientId?: number;
  },
  context?: HttpContext

): Observable<Array<ClientSupportApiContractListClientResponse>> {

    return this.apiSupportListClientGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<ClientSupportApiContractListClientResponse>>) => r.body as Array<ClientSupportApiContractListClientResponse>)
    );
  }

  /**
   * Path part for operation apiSupportGetClientDataIdGet
   */
  static readonly ApiSupportGetClientDataIdGetPath = '/api/Support/GetClientDataId';

  /**
   * Get client's data by ID.
   *
   * Returns client's data by ID
   * ### Claim
   *  boservice.support.getclientdataid
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSupportGetClientDataIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportGetClientDataIdGet$Plain$Response(params?: {

    /**
     * * language code
     */
    lang?: string;

    /**
     * * client's id
     */
    clientId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ClientSupportApiContractGetClientDataIdResponse>> {

    const rb = new RequestBuilder(this.rootUrl, SupportService.ApiSupportGetClientDataIdGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('clientId', params.clientId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClientSupportApiContractGetClientDataIdResponse>;
      })
    );
  }

  /**
   * Get client's data by ID.
   *
   * Returns client's data by ID
   * ### Claim
   *  boservice.support.getclientdataid
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSupportGetClientDataIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportGetClientDataIdGet$Plain(params?: {

    /**
     * * language code
     */
    lang?: string;

    /**
     * * client's id
     */
    clientId?: number;
  },
  context?: HttpContext

): Observable<ClientSupportApiContractGetClientDataIdResponse> {

    return this.apiSupportGetClientDataIdGet$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<ClientSupportApiContractGetClientDataIdResponse>) => r.body as ClientSupportApiContractGetClientDataIdResponse)
    );
  }

  /**
   * Get client's data by ID.
   *
   * Returns client's data by ID
   * ### Claim
   *  boservice.support.getclientdataid
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSupportGetClientDataIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportGetClientDataIdGet$Response(params?: {

    /**
     * * language code
     */
    lang?: string;

    /**
     * * client's id
     */
    clientId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ClientSupportApiContractGetClientDataIdResponse>> {

    const rb = new RequestBuilder(this.rootUrl, SupportService.ApiSupportGetClientDataIdGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('clientId', params.clientId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClientSupportApiContractGetClientDataIdResponse>;
      })
    );
  }

  /**
   * Get client's data by ID.
   *
   * Returns client's data by ID
   * ### Claim
   *  boservice.support.getclientdataid
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSupportGetClientDataIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportGetClientDataIdGet(params?: {

    /**
     * * language code
     */
    lang?: string;

    /**
     * * client's id
     */
    clientId?: number;
  },
  context?: HttpContext

): Observable<ClientSupportApiContractGetClientDataIdResponse> {

    return this.apiSupportGetClientDataIdGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<ClientSupportApiContractGetClientDataIdResponse>) => r.body as ClientSupportApiContractGetClientDataIdResponse)
    );
  }

  /**
   * Path part for operation apiSupportListTrmGet
   */
  static readonly ApiSupportListTrmGetPath = '/api/Support/ListTrm';

  /**
   * List of agencies (PTA).
   *
   * Getting a list of agencies (PTA, Transport managers) for prepaid tickets
   * ### Claim
   *  boservice.support.listtrm
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSupportListTrmGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportListTrmGet$Plain$Response(params?: {

    /**
     * *	language code
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<ClientSupportApiContractListTrmResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, SupportService.ApiSupportListTrmGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ClientSupportApiContractListTrmResponse>>;
      })
    );
  }

  /**
   * List of agencies (PTA).
   *
   * Getting a list of agencies (PTA, Transport managers) for prepaid tickets
   * ### Claim
   *  boservice.support.listtrm
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSupportListTrmGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportListTrmGet$Plain(params?: {

    /**
     * *	language code
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<Array<ClientSupportApiContractListTrmResponse>> {

    return this.apiSupportListTrmGet$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<ClientSupportApiContractListTrmResponse>>) => r.body as Array<ClientSupportApiContractListTrmResponse>)
    );
  }

  /**
   * List of agencies (PTA).
   *
   * Getting a list of agencies (PTA, Transport managers) for prepaid tickets
   * ### Claim
   *  boservice.support.listtrm
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSupportListTrmGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportListTrmGet$Response(params?: {

    /**
     * *	language code
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<ClientSupportApiContractListTrmResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, SupportService.ApiSupportListTrmGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ClientSupportApiContractListTrmResponse>>;
      })
    );
  }

  /**
   * List of agencies (PTA).
   *
   * Getting a list of agencies (PTA, Transport managers) for prepaid tickets
   * ### Claim
   *  boservice.support.listtrm
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSupportListTrmGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportListTrmGet(params?: {

    /**
     * *	language code
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<Array<ClientSupportApiContractListTrmResponse>> {

    return this.apiSupportListTrmGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<ClientSupportApiContractListTrmResponse>>) => r.body as Array<ClientSupportApiContractListTrmResponse>)
    );
  }

}
