import { ChangeDetectionStrategy, Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { AbstractControl, FormGroup, Validators } from '@angular/forms';
import { AddOpNoteRequest } from '@api/models/ClientSupportApi/Contract/Notes/add-op-note-request';
import { AbstractNoteFormField, AbstractNoteFormService } from '@ui/form-abstract-note/abstract-note.form.service';


export interface AbstractNoteFormBody {
    note?: string;
}

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'ui-form-abstract-note',
    templateUrl: './form-abstract-note.component.html',
    styleUrls: ['./form-abstract-note.component.scss'],
    providers: [AbstractNoteFormService],
})
export class FormAbstractNoteComponent {
    /**
     * Server pending flag
     */
    @Input() public pending: boolean;
    /**
     * Submit form event
     *
     * @returns form data
     */
    @Output() public submitForm: EventEmitter<AddOpNoteRequest> =
        new EventEmitter<AddOpNoteRequest>();
    /**
     * Cancel click event
     */
    @Output() public cancelClick: EventEmitter<void> = new EventEmitter<void>();

    readonly #abstractNoteFormService: AbstractNoteFormService = inject(AbstractNoteFormService);

    /**
     * Form object
     */
    public readonly form: FormGroup = this.#abstractNoteFormService.createReactiveForm();

    protected readonly abstractNoteFormField: typeof AbstractNoteFormField = AbstractNoteFormField;

    protected get noteControl(): AbstractControl {
        return this.form.get(AbstractNoteFormField.Note);
    }

    /**
     * Required note flag
     */
    @Input() public set noteRequired(flag: boolean) {
        if (flag) {
            this.noteControl.setValidators([Validators.required]);
        } else {
            this.noteControl.clearValidators();
        }
    }

    public handlerSubmit(): void {
        this.submitForm.emit(this.form.getRawValue() as AbstractNoteFormBody);

        this.noteControl.clearValidators();
        this.noteControl.reset();
    }
}
