<div class="container-fluid d-print-none bg-brand">
    <div class="row">
        <div class="col px-md-8">
            <header class="d-flex align-items-center justify-content-between flex-nowrap flex-row">
                <ui-logo [logo]="logo"></ui-logo>

                <ui-icon
                    *ngIf="user$ | async"
                    class="ms-auto"
                    [type]="'lnr-exit'"
                    [iconClass]="'pseudo--white'"
                    [titleText]="'ui.user-card-menu.logout' | translate"
                    [routerLink]="[routeEntity.Root, routeEntity.Logout]" [queryParams]="{ redirectToLoginInModule: true}">

                </ui-icon>

                <ui-lang-checker
                    class="ms-3"
                    [selected]="selectedLang"
                    [languages]="languages | async"
                    (changeLang)="handlerChangeLang($event)"
                ></ui-lang-checker>
            </header>
        </div>
    </div>
</div>
