import { StartWithUnit } from '@core/operators/start-with-unit';
import { inject, Injectable } from '@angular/core';
import { RxState } from '@rx-angular/state';
import { ValError } from '@api/models/AnalyticCommon/Models/val-error';
import { MonitoringWebService } from '@api/services/monitoring-web.service';
import { RxActionFactory, RxActions } from '@rx-angular/state/actions';
import { Observable } from 'rxjs';
import { LocalStorage } from 'ngx-webstorage';
import { StorageKeys } from '@core/storage/storage.keys';


interface IMonitoringErrorFilterState {
    loading: Record<StartWithUnit, boolean>;
    errorsList: ValError[];
    errorFilterValue: number[];
}

interface IMonitoringErrorFilterAction {
    requestErrorsList: ValError[];
    setErrorFilterValue: number[];
}

@Injectable()
export class MonitoringErrorFilterState extends RxState<IMonitoringErrorFilterState> {
    @LocalStorage(StorageKeys.MonitoringFilterError)
    public errorFilterValueFromStorage: number[];

    readonly #actions: RxActions<IMonitoringErrorFilterAction> = new RxActionFactory<IMonitoringErrorFilterAction>().create();
    readonly #monitoringWebService: MonitoringWebService = inject(MonitoringWebService);

    public readonly errorsList$: Observable<ValError[]> = this.select('errorsList');
    public readonly errorFilterValue$: Observable<number[]> = this.select('errorFilterValue');
    public readonly loading$: Observable<Record<StartWithUnit, boolean>> = this.select('loading');

    constructor() {
        super();

        this.setDefaultState();
        this.connectSelectors();

        this.requestErrorList();
    }

    public requestErrorList(): void {
        this.hold(
            this.#monitoringWebService.apiMonitoringWebErrorsGet(),
            this.#actions.requestErrorsList,
        );
    }

    public setErrorFilterValue(value: number[]): void {
        this.errorFilterValueFromStorage = value;
        this.#actions.setErrorFilterValue(value);
    }

    private setDefaultState(): void {
        this.set({
            errorsList: null,
            errorFilterValue: this.errorFilterValueFromStorage || [],
            loading: null,
        });
    }

    private connectSelectors(): void {
        this.connect('errorsList', this.#actions.requestErrorsList$);
        this.connect('errorFilterValue', this.#actions.setErrorFilterValue$);
    }
}
