export enum RoutingQueryParamsName {
    DocType = 'docType',
    PrvCatId = 'prvCatId',
    EvtId = 'evtId',
    PrgId = 'prgId',
    Key = 'key',
    SlrId = 'slrId',
    PlaceId = 'placeId',
    PlaceIds = 'placeIds',
    ProviderId = 'providerId',
    ClientId = 'clientId',
    RouteId = 'routeId',
    UsrId = 'usrId',
    UserType = 'userType',
    GroupId = 'groupId',
    ApvId = 'apvId',
    ListId = 'listId',
    TrgId = 'trgId',
    DtgId = 'dtgId',
    VersionId = 'versionId',
    PathMode = 'pathMode',
    TariffId = 'tariffId',
    CopyTariffId = 'copyTariffId',
    Name = 'name',
    Editor = 'editor',
    Code = 'code',
    TtpId = 'ttpId',
}
