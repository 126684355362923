export enum StartWithUnit {
    /** Auth */
    SignIn,
    ChangeMyPassword,
    /** B2b */
    RequestBatches,
    RequestAddPrintedTicketBatch,
    RequestRemovePrintedTicketBatch,
    RequestB2BPrograms,
    RequestAddB2BProgram,
    RequestUpdateB2BProgram,
    RequestRemoveB2BProgram,
    RequestB2BServices,
    RequestB2BProgramLoads,
    RequestB2BProgramUploadFile,
    RequestSearchInB2BLoads,
    RequestB2BProgramParticipants,
    B2BAddRider,
    /** Events */
    RequestEvents,
    RequestGetDataEvent,
    RequestAddEvent,
    RequestDeleteEvent,
    RequestEventListPackages,
    RequestInventoryListPackages,
    RequestInventoryAddPackage,
    RequestInventoryRepackPackage,
    RequestInventoryDeletePackage,
    RequestUnlinkPackageFromEvent,
    RequestExportEventListId,
    /** MonTech */
    RequestMonTechValidators,
    /** Ref */
    RequestDocTypes,
    RequestAddDocType,
    RequestDeleteDocType,
    /** Load */
    RequestLoadHistory,
    RequestUploadFile,
    /** Agents */
    RequestAgentAdd,
    RequestAgentDelete,
    RequestAgentDepartmentAdd,
    RequestAgentDepartmentDelete,
    RequestAgentUploadCards,
    RequestAddFundTransfer,
    RequestSetStatusFundTransfer,
    RequestFundsTransfers,
    /** Refs */
    RequestLocations,
    AddLocation,
    EditLocationShiftMode,
    AddValidatorToLocation,
    RemoveValidatorFromLocation,
    CloseValidatorShift,
    RemoveLocation,
    RemoveProvider,
    AddProvider,
    SetProviderRate,
    SetProviderInsurance,
    AddProviderDepartment,
    RemoveProviderDepartment,
    AddProviderValidators,
    RemoveProviderValidators,
    AddProviderService,
    RemoveProviderService,
    CreateTariffVersion,
    SetSortTariffMenu,
    AddOrEditTariffGroup,
    DeleteTariffGroup,
    AddOrEditReportGroup,
    DeleteReportGroup,
    DeleteRoute,
    AddOrEditRoute,
    AddOrEditZone,
    DeleteZone,
    AddOrEditStop,
    DeleteStop,
    AddOrEditStopToRoute,
    DeleteStopFromRoute,
    SaveRouteLines,
    AddOrEditSpecificValidityPeriod,
    AddOrEditSpecificValidityPeriodInterval,
    DeleteSpecificValidityPeriod,
    DeleteSpecificValidityPeriodInterval,
    /** SysAdm */
    AddOrEditDesignElementsVersion,
    RemoveDesignElementsVersion,
    AddOrEditDesignElement,
    RemoveDesignElement,
    RequestDesignElementList,
    AddBanner,
    RemoveBanner,
    /** AntiFraud */
    ListRejected,
    SetReason,
    SetIdStatus,
    ListDetected,
    ListDetectedPass,
    ListIds,
    ProcessDetectedItems,
    /** Rep */
    AddReport,
    AddDashboard,
    EditDashboard,
    EditReport,
    CopyReport,
    CopyDashboard,
    DeleteReport,
    DeleteDashboard,
    CreateFolder,
    EditFolder,
    DeleteFolder,
    LoadData,
    SaveDashboardResources,
    /** Support */
    SendClientMessage,
    SearchTicket,
    SearchCoupon,
    RefundsList,
    SetRefundStatus,
    ParkingPermitsList,
    CreateParkingPermit,
    DeleteParkingPermit,
    SearchClient,
    BlockUnblockClient,
    CreateRefund,
    ReduceBalance,
    CheckOrSetMobilePhone,
    CheckOrSetEmail,
    CancelClientTicket,
    RefundClientTicket,
    LoadClientMediums,
    DeleteClientMedium,
    LoadClientHistory,
    AddHistoryNoteItem,
    TransferClientTicket,
    AddNoteForOperation,
    LoadDocTypes,
    SetClientOrMediumPrivilege,
    DeleteClientOrMediumPrivilege,
    SetClientPersonalData,
    AddCourtesyTicket,
    SearchClients,
}
