import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfirmWithNoteDialogComponent } from './confirm-with-note-dialog.component';
import { FormAbstractNoteModule } from '@ui/form-abstract-note/form-abstract-note.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [ConfirmWithNoteDialogComponent],
  imports: [CommonModule, FormAbstractNoteModule, TranslateModule],
  exports: [ConfirmWithNoteDialogComponent],
})
export class ConfirmWithNoteDialogModule {}
