import { ChangeDetectionStrategy, Component, inject, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogData } from '@core/dialog/dialog/dialog.tokens';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PushService } from '@core/push/push.service';
import { PushCodeModel } from '@core/push/push-code.model';


@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'popup-confirm-dialog',
    templateUrl: './confirm-dialog.component.html',
    styleUrls: ['./confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent implements OnDestroy {
    readonly #push: PushService = inject(PushService);

    readonly pending$: Observable<boolean> | undefined =
        this.data.params.pending$;
    private readonly destroy$: Subject<unknown> = new Subject<unknown>();

    constructor(
        @Inject(MAT_DIALOG_DATA) private data: DialogData,
        public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    ) {
    }

    public handlerClickConfirm(): void {
        this.data.params
            .confirmFunction()
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => {
                this.#push.success(PushCodeModel.SuccessDelete);
                this.handlerClickCancel();
            });
    }

    public handlerClickCancel(): void {
        this.dialogRef.close();
    }

    public ngOnDestroy(): void {
        this.destroy$.next(null);
        this.destroy$.complete();
    }
}
