import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { ConfirmDialogModule } from './confirm-dialog/confirm-dialog.module';
import { DialogModule } from './dialog/dialog.module';
import { ReactiveFormsModule } from '@angular/forms';
import { ConfirmWithNoteDialogModule } from '@core/dialog/confirm-with-note-dialog/confirm-with-note-dialog.module';


@NgModule({
    imports: [
        CommonModule,
        MatDialogModule,
        TranslateModule,
        ConfirmDialogModule,
        DialogModule,
        ReactiveFormsModule,
    ],
    exports: [DialogModule, ConfirmDialogModule, ConfirmWithNoteDialogModule],
})
export class PopupsModule {
}
