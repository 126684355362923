export enum RouteEntity {
  Empty = '',
  Root = '/',
  Users = 'users',
  Divisions = 'divisions',
  CookiePolicy = 'cookie-policy',
  UserSettings = 'settings-user',
  Login = 'login',
  Logout = 'logout',
  Error = 'error',
  Error404 = '404',
  Error403 = '403',
  Parameters = 'parameters',
  Appearance = 'appearance',
  About = 'about',
  Elements = 'elements',
  Advertising = 'advertising',
  Design = 'design',
  Messages = 'messages',
  History = 'history',
  Parking = 'parking',
  Courtesy = 'courtesy',
  Privileges = 'privileges',
  TicketSearch = 'ticket-search',
  CouponSearch = 'coupon-search',
  Refunds = 'refunds',
  Clients = 'clients',
  ReportsEditor = 'reports-editor',
  Reports = 'reports',
  View = 'view',
  Dashboards = 'dashboards',
  DashboardsEditor = 'dashboards-editor',
  LoadData = 'load-data',
  Info = 'info',
  Resources = 'resources',
  Add = 'add',
  List = 'list',
  Agents = 'agents',
  Orders = 'orders',
  Programs = 'programs',
  Uploads = 'uploads',
  Search = 'search',
  Invoices = 'invoices',
  Rejected = 'rejected',
  Suspicious = 'suspicious',
  Settings = 'settings',
  Reasons = 'reasons',
  Ids = 'ids',
  Applications = 'applications',
  Coupon = 'coupon',
  Discounts = 'discounts',
  Monitoring = 'monitoring',
  Map = 'map',
  Scheme = 'scheme',
  Mobile = 'mobile',
  Providers = 'providers',
  Places = 'places',
  Privcats = 'privcats',
  Routes = 'routes',
  Route = 'route',
  Tariffs = 'tariffs',
  Validators = 'validators',
  QrCode = 'qr-code',
  Profile = 'profile',
  Depts = 'depts',
  Services = 'services',
  Insurance = 'insurance',
  Rate = 'rate',
  Common = 'common',
  Stops = 'stops',
  Lines = 'lines',
  Zones = 'zones',
  Pass = 'pass',
  Ticket = 'ticket',
  Cap = 'cap',
  Domain = 'domain',
  FormPass = 'form-pass',
  FormTicket = 'form-ticket',
  BalanceLimits = 'balance-limits',
  FireStage = 'fire-stage',
  ZonePrice = 'zone-price',
  ProviderShares = 'provider-shares',
  UpCharge = 'up-charge',
  Fares = 'fares',
  ProductGroups = 'product-groups',
  ReportGroups = 'report-groups',
  SpecificValidityPeriods = 'specific-validity-periods',
  SpecificDates = 'specific-dates',
  PrintedTickets = 'printed-tickets',
  Events = 'events',
  Inventory = 'inventory',
  Packages  = 'packages',
  Repack  = 'repack',
  OrgRoles  = 'org-roles',
  DocTypes  = 'doc-types',
  Loads  = 'loads',
  AddRider  = 'add-rider',
  SearchRider  = 'search-rider',
}
