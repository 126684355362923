import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { CommonAppModule } from '@ui/common-app/common-app.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { DomDeps, HttpTranslateLoaderFactory, startupAppConfigs, startupDeps } from '@core/app/app.module.helpers';
import { HttpBackend } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import { DefaultPushConfig } from '@core/push/push.config';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { PopupsModule } from '@popups/popups.module';
import { CommonAppComponent } from '@ui/common-app/common-app.component';
import { MonitoringErrorFilterState } from '@libs/monitoring/state/monitoring-error-filter.state';


@NgModule({
    imports: [
        CommonAppModule,
        BrowserModule,
        AppRoutingModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpTranslateLoaderFactory,
                deps: [HttpBackend],
            },
        }),
        ToastrModule.forRoot(DefaultPushConfig),
        NgxWebstorageModule.forRoot(),
        PopupsModule,
    ],
    providers: [
        ...DomDeps,
        {
            provide: APP_INITIALIZER,
            useFactory: startupAppConfigs,
            deps: startupDeps,
            multi: true,
        },
        MonitoringErrorFilterState,
    ],
    bootstrap: [CommonAppComponent],
})
export class AppModule {
}
