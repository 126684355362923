/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { Refund as ClientSupportApiContractRefund } from '../models/ClientSupportApi/Contract/refund';
import { AddTicketRefundRequest as ClientSupportApiContractRefundsAddTicketRefundRequest } from '../models/ClientSupportApi/Contract/Refunds/add-ticket-refund-request';
import { GetRefundWarningResponse as ClientSupportApiContractRefundsGetRefundWarningResponse } from '../models/ClientSupportApi/Contract/Refunds/get-refund-warning-response';
import { ListRefundErrors as ClientSupportApiContractRefundsListRefundErrors } from '../models/ClientSupportApi/Contract/Refunds/list-refund-errors';
import { ListRefundTransResponse as ClientSupportApiContractRefundsListRefundTransResponse } from '../models/ClientSupportApi/Contract/Refunds/list-refund-trans-response';
import { RefundData as ClientSupportApiContractRefundsRefundData } from '../models/ClientSupportApi/Contract/Refunds/refund-data';
import { RefundRequest as ClientSupportApiContractRefundsRefundRequest } from '../models/ClientSupportApi/Contract/Refunds/refund-request';
import { RefundTransaction as ClientSupportApiContractRefundsRefundTransaction } from '../models/ClientSupportApi/Contract/Refunds/refund-transaction';
import { SetRefundStatusRequest as ClientSupportApiContractRefundsSetRefundStatusRequest } from '../models/ClientSupportApi/Contract/Refunds/set-refund-status-request';

@Injectable({
  providedIn: 'root',
})
export class RefundService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiSupportClientsIdRefundPost
   */
  static readonly ApiSupportClientsIdRefundPostPath = '/api/Support/clients/{id}/refund';

  /**
   * Create a return request.
   *
   * ### Claim
   *  boservice.support.addrefund
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSupportClientsIdRefundPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSupportClientsIdRefundPost$Plain$Response(params: {

    /**
     * The customer ID
     */
    id: number;

    /**
     * Interface language
     */
    lang?: string;

    /**
     * Return request
     */
    body?: ClientSupportApiContractRefundsRefundRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ClientSupportApiContractRefund>> {

    const rb = new RequestBuilder(this.rootUrl, RefundService.ApiSupportClientsIdRefundPostPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('lang', params.lang, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClientSupportApiContractRefund>;
      })
    );
  }

  /**
   * Create a return request.
   *
   * ### Claim
   *  boservice.support.addrefund
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSupportClientsIdRefundPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSupportClientsIdRefundPost$Plain(params: {

    /**
     * The customer ID
     */
    id: number;

    /**
     * Interface language
     */
    lang?: string;

    /**
     * Return request
     */
    body?: ClientSupportApiContractRefundsRefundRequest
  },
  context?: HttpContext

): Observable<ClientSupportApiContractRefund> {

    return this.apiSupportClientsIdRefundPost$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<ClientSupportApiContractRefund>) => r.body as ClientSupportApiContractRefund)
    );
  }

  /**
   * Create a return request.
   *
   * ### Claim
   *  boservice.support.addrefund
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSupportClientsIdRefundPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSupportClientsIdRefundPost$Response(params: {

    /**
     * The customer ID
     */
    id: number;

    /**
     * Interface language
     */
    lang?: string;

    /**
     * Return request
     */
    body?: ClientSupportApiContractRefundsRefundRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ClientSupportApiContractRefund>> {

    const rb = new RequestBuilder(this.rootUrl, RefundService.ApiSupportClientsIdRefundPostPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('lang', params.lang, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClientSupportApiContractRefund>;
      })
    );
  }

  /**
   * Create a return request.
   *
   * ### Claim
   *  boservice.support.addrefund
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSupportClientsIdRefundPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSupportClientsIdRefundPost(params: {

    /**
     * The customer ID
     */
    id: number;

    /**
     * Interface language
     */
    lang?: string;

    /**
     * Return request
     */
    body?: ClientSupportApiContractRefundsRefundRequest
  },
  context?: HttpContext

): Observable<ClientSupportApiContractRefund> {

    return this.apiSupportClientsIdRefundPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<ClientSupportApiContractRefund>) => r.body as ClientSupportApiContractRefund)
    );
  }

  /**
   * Path part for operation apiSupportRefundWarningGet
   */
  static readonly ApiSupportRefundWarningGetPath = '/api/Support/refund/warning';

  /**
   * Receiving the warning text for RM TP.
   *
   * ### Claim
   *  boservice.support.getrefundwarning
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSupportRefundWarningGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportRefundWarningGet$Plain$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ClientSupportApiContractRefundsGetRefundWarningResponse>> {

    const rb = new RequestBuilder(this.rootUrl, RefundService.ApiSupportRefundWarningGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClientSupportApiContractRefundsGetRefundWarningResponse>;
      })
    );
  }

  /**
   * Receiving the warning text for RM TP.
   *
   * ### Claim
   *  boservice.support.getrefundwarning
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSupportRefundWarningGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportRefundWarningGet$Plain(params?: {

    /**
     * Interface language
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<ClientSupportApiContractRefundsGetRefundWarningResponse> {

    return this.apiSupportRefundWarningGet$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<ClientSupportApiContractRefundsGetRefundWarningResponse>) => r.body as ClientSupportApiContractRefundsGetRefundWarningResponse)
    );
  }

  /**
   * Receiving the warning text for RM TP.
   *
   * ### Claim
   *  boservice.support.getrefundwarning
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSupportRefundWarningGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportRefundWarningGet$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ClientSupportApiContractRefundsGetRefundWarningResponse>> {

    const rb = new RequestBuilder(this.rootUrl, RefundService.ApiSupportRefundWarningGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClientSupportApiContractRefundsGetRefundWarningResponse>;
      })
    );
  }

  /**
   * Receiving the warning text for RM TP.
   *
   * ### Claim
   *  boservice.support.getrefundwarning
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSupportRefundWarningGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportRefundWarningGet(params?: {

    /**
     * Interface language
     */
    lang?: string;
  },
  context?: HttpContext

): Observable<ClientSupportApiContractRefundsGetRefundWarningResponse> {

    return this.apiSupportRefundWarningGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<ClientSupportApiContractRefundsGetRefundWarningResponse>) => r.body as ClientSupportApiContractRefundsGetRefundWarningResponse)
    );
  }

  /**
   * Path part for operation apiSupportRefundGet
   */
  static readonly ApiSupportRefundGetPath = '/api/Support/refund';

  /**
   * List of return requests.
   *
   * ### Claim
   *  boservice.support.listrefunds
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSupportRefundGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportRefundGet$Plain$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * date with
     */
    start?: string;

    /**
     * date on
     */
    end?: string;

    /**
     * order type: 0 – all; 1 - completed; 2 - not completed
     */
    refType?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<ClientSupportApiContractRefundsRefundData>>> {

    const rb = new RequestBuilder(this.rootUrl, RefundService.ApiSupportRefundGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('start', params.start, {});
      rb.query('end', params.end, {});
      rb.query('refType', params.refType, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ClientSupportApiContractRefundsRefundData>>;
      })
    );
  }

  /**
   * List of return requests.
   *
   * ### Claim
   *  boservice.support.listrefunds
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSupportRefundGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportRefundGet$Plain(params?: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * date with
     */
    start?: string;

    /**
     * date on
     */
    end?: string;

    /**
     * order type: 0 – all; 1 - completed; 2 - not completed
     */
    refType?: number;
  },
  context?: HttpContext

): Observable<Array<ClientSupportApiContractRefundsRefundData>> {

    return this.apiSupportRefundGet$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<ClientSupportApiContractRefundsRefundData>>) => r.body as Array<ClientSupportApiContractRefundsRefundData>)
    );
  }

  /**
   * List of return requests.
   *
   * ### Claim
   *  boservice.support.listrefunds
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSupportRefundGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportRefundGet$Response(params?: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * date with
     */
    start?: string;

    /**
     * date on
     */
    end?: string;

    /**
     * order type: 0 – all; 1 - completed; 2 - not completed
     */
    refType?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<ClientSupportApiContractRefundsRefundData>>> {

    const rb = new RequestBuilder(this.rootUrl, RefundService.ApiSupportRefundGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('start', params.start, {});
      rb.query('end', params.end, {});
      rb.query('refType', params.refType, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ClientSupportApiContractRefundsRefundData>>;
      })
    );
  }

  /**
   * List of return requests.
   *
   * ### Claim
   *  boservice.support.listrefunds
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSupportRefundGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportRefundGet(params?: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * date with
     */
    start?: string;

    /**
     * date on
     */
    end?: string;

    /**
     * order type: 0 – all; 1 - completed; 2 - not completed
     */
    refType?: number;
  },
  context?: HttpContext

): Observable<Array<ClientSupportApiContractRefundsRefundData>> {

    return this.apiSupportRefundGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<ClientSupportApiContractRefundsRefundData>>) => r.body as Array<ClientSupportApiContractRefundsRefundData>)
    );
  }

  /**
   * Path part for operation apiSupportRefundRefundGet
   */
  static readonly ApiSupportRefundRefundGetPath = '/api/Support/refund/{refund}';

  /**
   * List of replenishment transactions for an order.
   *
   * ### Claim
   *  boservice.support.listrefundtransaction
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSupportRefundRefundGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportRefundRefundGet$Plain$Response(params: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * return request ID
     */
    refund: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<ClientSupportApiContractRefundsRefundTransaction>>> {

    const rb = new RequestBuilder(this.rootUrl, RefundService.ApiSupportRefundRefundGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.path('refund', params.refund, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ClientSupportApiContractRefundsRefundTransaction>>;
      })
    );
  }

  /**
   * List of replenishment transactions for an order.
   *
   * ### Claim
   *  boservice.support.listrefundtransaction
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSupportRefundRefundGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportRefundRefundGet$Plain(params: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * return request ID
     */
    refund: number;
  },
  context?: HttpContext

): Observable<Array<ClientSupportApiContractRefundsRefundTransaction>> {

    return this.apiSupportRefundRefundGet$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<ClientSupportApiContractRefundsRefundTransaction>>) => r.body as Array<ClientSupportApiContractRefundsRefundTransaction>)
    );
  }

  /**
   * List of replenishment transactions for an order.
   *
   * ### Claim
   *  boservice.support.listrefundtransaction
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSupportRefundRefundGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportRefundRefundGet$Response(params: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * return request ID
     */
    refund: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<ClientSupportApiContractRefundsRefundTransaction>>> {

    const rb = new RequestBuilder(this.rootUrl, RefundService.ApiSupportRefundRefundGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.path('refund', params.refund, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ClientSupportApiContractRefundsRefundTransaction>>;
      })
    );
  }

  /**
   * List of replenishment transactions for an order.
   *
   * ### Claim
   *  boservice.support.listrefundtransaction
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSupportRefundRefundGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportRefundRefundGet(params: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * return request ID
     */
    refund: number;
  },
  context?: HttpContext

): Observable<Array<ClientSupportApiContractRefundsRefundTransaction>> {

    return this.apiSupportRefundRefundGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<ClientSupportApiContractRefundsRefundTransaction>>) => r.body as Array<ClientSupportApiContractRefundsRefundTransaction>)
    );
  }

  /**
   * Path part for operation apiSupportRefundErrorRefundGet
   */
  static readonly ApiSupportRefundErrorRefundGetPath = '/api/Support/refund/error/{refund}';

  /**
   * Viewing data for a customer ticket with an "Error" status.
   *
   * ### Claim
   *  boservice.support.listrefunderrors
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSupportRefundErrorRefundGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportRefundErrorRefundGet$Plain$Response(params: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * return request ID
     */
    refund: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ClientSupportApiContractRefundsListRefundErrors>> {

    const rb = new RequestBuilder(this.rootUrl, RefundService.ApiSupportRefundErrorRefundGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.path('refund', params.refund, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClientSupportApiContractRefundsListRefundErrors>;
      })
    );
  }

  /**
   * Viewing data for a customer ticket with an "Error" status.
   *
   * ### Claim
   *  boservice.support.listrefunderrors
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSupportRefundErrorRefundGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportRefundErrorRefundGet$Plain(params: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * return request ID
     */
    refund: number;
  },
  context?: HttpContext

): Observable<ClientSupportApiContractRefundsListRefundErrors> {

    return this.apiSupportRefundErrorRefundGet$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<ClientSupportApiContractRefundsListRefundErrors>) => r.body as ClientSupportApiContractRefundsListRefundErrors)
    );
  }

  /**
   * Viewing data for a customer ticket with an "Error" status.
   *
   * ### Claim
   *  boservice.support.listrefunderrors
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSupportRefundErrorRefundGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportRefundErrorRefundGet$Response(params: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * return request ID
     */
    refund: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ClientSupportApiContractRefundsListRefundErrors>> {

    const rb = new RequestBuilder(this.rootUrl, RefundService.ApiSupportRefundErrorRefundGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.path('refund', params.refund, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClientSupportApiContractRefundsListRefundErrors>;
      })
    );
  }

  /**
   * Viewing data for a customer ticket with an "Error" status.
   *
   * ### Claim
   *  boservice.support.listrefunderrors
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSupportRefundErrorRefundGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportRefundErrorRefundGet(params: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * return request ID
     */
    refund: number;
  },
  context?: HttpContext

): Observable<ClientSupportApiContractRefundsListRefundErrors> {

    return this.apiSupportRefundErrorRefundGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<ClientSupportApiContractRefundsListRefundErrors>) => r.body as ClientSupportApiContractRefundsListRefundErrors)
    );
  }

  /**
   * Path part for operation apiSupportRefundClientTktIdPost
   */
  static readonly ApiSupportRefundClientTktIdPostPath = '/api/Support/refund/{client}/{tktId}';

  /**
   * Ticket refund.
   *
   * ### Claim
   *  boservice.support.addticketrefund
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSupportRefundClientTktIdPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSupportRefundClientTktIdPost$Response(params: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * The customer ID
     */
    client: number;

    /**
     * Ticket ID
     */
    tktId: number;
    body?: ClientSupportApiContractRefundsAddTicketRefundRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, RefundService.ApiSupportRefundClientTktIdPostPath, 'post');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.path('client', params.client, {});
      rb.path('tktId', params.tktId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Ticket refund.
   *
   * ### Claim
   *  boservice.support.addticketrefund
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSupportRefundClientTktIdPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSupportRefundClientTktIdPost(params: {

    /**
     * Interface language
     */
    lang?: string;

    /**
     * The customer ID
     */
    client: number;

    /**
     * Ticket ID
     */
    tktId: number;
    body?: ClientSupportApiContractRefundsAddTicketRefundRequest
  },
  context?: HttpContext

): Observable<void> {

    return this.apiSupportRefundClientTktIdPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiSupportSetRefundStatusPost
   */
  static readonly ApiSupportSetRefundStatusPostPath = '/api/Support/SetRefundStatus';

  /**
   * Refund claim status change (manual).
   *
   * Refund claim status change manually by operator
   * ### Claim
   *  boservice.support.setrefundstatus
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSupportSetRefundStatusPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSupportSetRefundStatusPost$Response(params?: {

    /**
     * * language code
     */
    lang?: string;
    body?: ClientSupportApiContractRefundsSetRefundStatusRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, RefundService.ApiSupportSetRefundStatusPostPath, 'post');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Refund claim status change (manual).
   *
   * Refund claim status change manually by operator
   * ### Claim
   *  boservice.support.setrefundstatus
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSupportSetRefundStatusPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSupportSetRefundStatusPost(params?: {

    /**
     * * language code
     */
    lang?: string;
    body?: ClientSupportApiContractRefundsSetRefundStatusRequest
  },
  context?: HttpContext

): Observable<void> {

    return this.apiSupportSetRefundStatusPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiSupportListRefundTransGet
   */
  static readonly ApiSupportListRefundTransGetPath = '/api/Support/ListRefundTrans';

  /**
   * List of refund transactions.
   *
   * List of refund transactions associated with selected refund claim
   * ### Claim
   *  boservice.support.listrefundtrans
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSupportListRefundTransGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportListRefundTransGet$Plain$Response(params?: {

    /**
     * * language code
     */
    lang?: string;

    /**
     * * refund claim id
     */
    refundId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<ClientSupportApiContractRefundsListRefundTransResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, RefundService.ApiSupportListRefundTransGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('refundId', params.refundId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ClientSupportApiContractRefundsListRefundTransResponse>>;
      })
    );
  }

  /**
   * List of refund transactions.
   *
   * List of refund transactions associated with selected refund claim
   * ### Claim
   *  boservice.support.listrefundtrans
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSupportListRefundTransGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportListRefundTransGet$Plain(params?: {

    /**
     * * language code
     */
    lang?: string;

    /**
     * * refund claim id
     */
    refundId?: number;
  },
  context?: HttpContext

): Observable<Array<ClientSupportApiContractRefundsListRefundTransResponse>> {

    return this.apiSupportListRefundTransGet$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<ClientSupportApiContractRefundsListRefundTransResponse>>) => r.body as Array<ClientSupportApiContractRefundsListRefundTransResponse>)
    );
  }

  /**
   * List of refund transactions.
   *
   * List of refund transactions associated with selected refund claim
   * ### Claim
   *  boservice.support.listrefundtrans
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSupportListRefundTransGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportListRefundTransGet$Response(params?: {

    /**
     * * language code
     */
    lang?: string;

    /**
     * * refund claim id
     */
    refundId?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<ClientSupportApiContractRefundsListRefundTransResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, RefundService.ApiSupportListRefundTransGetPath, 'get');
    if (params) {
      rb.query('lang', params.lang, {});
      rb.query('refundId', params.refundId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ClientSupportApiContractRefundsListRefundTransResponse>>;
      })
    );
  }

  /**
   * List of refund transactions.
   *
   * List of refund transactions associated with selected refund claim
   * ### Claim
   *  boservice.support.listrefundtrans
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSupportListRefundTransGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSupportListRefundTransGet(params?: {

    /**
     * * language code
     */
    lang?: string;

    /**
     * * refund claim id
     */
    refundId?: number;
  },
  context?: HttpContext

): Observable<Array<ClientSupportApiContractRefundsListRefundTransResponse>> {

    return this.apiSupportListRefundTransGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<ClientSupportApiContractRefundsListRefundTransResponse>>) => r.body as Array<ClientSupportApiContractRefundsListRefundTransResponse>)
    );
  }

}
