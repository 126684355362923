import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    inject,
    Input,
    OnInit,
    Output,
    ViewEncapsulation,
} from '@angular/core';
import {
    ControlValueAccessor,
    FormControl,
    FormControlStatus,
    NgControl,
    ReactiveFormsModule,
    Validators,
} from '@angular/forms';
import { NgClass, NgIf } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ValidationMessagePipe } from '@pipes/validation-message/validation-message.pipe';


@Component({
    selector: 'ui-form-textarea',
    templateUrl: './form-textarea.component.html',
    styleUrls: ['./form-textarea.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [
        ReactiveFormsModule,
        ValidationMessagePipe,
        TranslateModule,
        NgClass,
        NgIf,
    ],
    host: {
        '[class.hidden-hint]': 'hideHint',
        'class': 'ui-form-textarea',
    },
})
export class FormTextareaComponent implements ControlValueAccessor, OnInit {
    @Input()
    public label: string = '';

    @Input()
    public placeholder: string = '';

    @Input()
    public hint: string = '';

    @Input()
    public autosizeMinRows: number = 1;

    @Input()
    public autosizeMaxRows: number = Number.MAX_SAFE_INTEGER;

    @Input()
    public additionalGroupClass: string;

    @Input()
    public disabled: boolean;

    @Input()
    public readonly: boolean = false;

    @Input()
    public hideHint?: boolean = false;

    @Input()
    public value: string;

    @Input()
    public id: string = 'ui-txt-' + Math.random().toString().replace('.', '');

    @Output()
    public enterPressed: EventEmitter<Event> = new EventEmitter<Event>();

    public inputControl: FormControl;

    private readonly ngControl: NgControl = inject(NgControl, { self: true, optional: true });

    private readonly cd: ChangeDetectorRef = inject(ChangeDetectorRef);

    private readonly destroy$: Subject<unknown> = new Subject<unknown>();

    public hasBlur: boolean;

    constructor() {
        if (this.ngControl) {
            this.ngControl.valueAccessor = this;
        }
    }

    public ngOnInit(): void {
        this.inputControl = this.ngControl.control as FormControl;

        this.disabled = this.inputControl.status === 'DISABLED';

        this.inputControl.statusChanges.pipe(
            takeUntil(this.destroy$),
        ).subscribe((status: FormControlStatus) => {
            this.disabled = status === 'DISABLED';
        });
    }

    public get isRequiredField(): boolean {
        return this.inputControl.hasValidator(Validators.required);
    }

    public ngOnDestroy() {
        this.destroy$.next(null);
        this.destroy$.complete();
    }

    public registerOnChange(fn: (v: string) => void): void {
    }

    public registerOnTouched(fn: () => void): void {
    }

    public writeValue(value: string): void {
        this.value = value;
    }

    public handlePressEnter(event: Event): void {
        this.enterPressed.emit(event);
    }

    public changeValue(event: Event): void {
        //@ts-ignore
        this.inputControl.setValue(event.target?.value);
        this.inputControl.markAsDirty();
        this.inputControl.markAsTouched();
        this.cd.markForCheck();
    }
}
